var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "comment-item" }, [
    _c(
      "div",
      { staticClass: "flex-row" },
      [
        _c("el-avatar", {
          staticClass: "comment-ai-avatar f-shrink",
          attrs: { size: 44, src: require("@/assets/images/saleAi.png") },
        }),
        _c("div", { staticClass: "f-grow" }, [
          _c("div", { staticClass: "flex-row-center f-between" }, [
            _c("span", { staticClass: "comment-ai" }, [_vm._v(_vm._s(_vm.ai))]),
            _c("span", { staticClass: "comment-time" }, [
              _vm._v(_vm._s(_vm.comment.aiReplyTime)),
            ]),
          ]),
          _c("div", { staticClass: "flex-row f-wrap comment-content" }, [
            _c("span", [_vm._v(_vm._s(_vm.comment.aiReplyContent))]),
            _c("span", { staticClass: "comment-username" }, [
              _vm._v("//@" + _vm._s(_vm.comment.username) + "："),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.comment.comment))]),
          ]),
          _c("div", { staticClass: "video-theme" }, [
            _c("div", { staticClass: "flex-row-center f-between" }, [
              _c("span", { staticClass: "comment-ai" }, [
                _vm._v(_vm._s(_vm.$t("sale.videoTopic"))),
              ]),
              _c("span", { staticClass: "comment-time" }, [
                _vm._v(
                  _vm._s(_vm.$t("sale.publishedOn")) +
                    "：" +
                    _vm._s(_vm.comment.publishTime)
                ),
              ]),
            ]),
            _c("div", { staticClass: "comment-content" }, [
              _c("span", [_vm._v(_vm._s(_vm.comment.postContent))]),
            ]),
            _vm.comment.url
              ? _c("video", {
                  ref: "videoPlayer",
                  staticClass: "video-theme-video",
                  attrs: { src: _vm.comment.url, controls: "", alt: "video" },
                })
              : _vm._e(),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }