<template>
  <div class="login-container flex-center">
    <div class="login-main flex-row">
      <div class="login-bg-50 login-left-radius flex-column-center">
        <img
          src="@/assets/images/login/bg-small.jpg"
          alt=""
          class="login-img-left login-left-radius"
          @load="onImageLoad"
        />
        <div
          class="left-logo"
          v-show="isOnLoad"
        >InfoPub</div>
        <div
          class="left-des f-grow"
          v-show="isOnLoad"
        >{{ $t('login.smartPublishSystem') }}</div>
        <div
          class="left-bottom"
          v-show="isOnLoad"
        >{{ $t('login.oneClickPublish') }}</div>
      </div>
      <div class="login-bg-50 login-right-radius flex-column">
        <el-form
          ref="registerForm"
          :model="registerForm"
          :rules="registerRules"
          label-position="right"
          label-width="0"
          class="register-form"
        >
          <div class="register-title">{{ $t('newRegister.registerUser') }}</div>
          <div class="go-login">{{ $t('newRegister.alreadyRegistered') }}
            <router-link
              class="link-type"
              :to="'/login'"
            >{{ $t('newRegister.login') }}</router-link>
          </div>
          <el-form-item prop="account">
            <el-input
              v-model="registerForm.account"
              type="text"
              autocomplete="off"
              maxlength="50"
              :placeholder="$t('newRegister.setUsername')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="registerForm.password"
              :type="showPassword ? 'text' : 'password'"
              autocomplete="off"
              maxlength="18"
              :placeholder="$t('newRegister.setPassword')"
              @keyup.enter.native="handleRegister"
            >
              <div
                class="el-input__icon input-icon passwrod-eye"
                slot="suffix"
              >
                <i
                  class="iconfont"
                  :class="showPassword ? 'icon-zhengyan' : 'icon-biyan'"
                  @click="togglePasswordVisibility"
                ></i>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="againPassword">
            <el-input
              v-model="registerForm.againPassword"
              :type="showAgainPassword ? 'text' : 'password'"
              autocomplete="off"
              maxlength="18"
              :placeholder="$t('newRegister.againPassword')"
              @keyup.enter.native="handleRegister"
            >
              <div
                class="el-input__icon input-icon passwrod-eye"
                slot="suffix"
              >
                <i
                  class="iconfont"
                  :class="showAgainPassword ? 'icon-zhengyan' : 'icon-biyan'"
                  @click="toggleAgainPasswordVisibility"
                ></i>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="registerForm.email"
              type="text"
              autocomplete="off"
              maxlength="50"
              :placeholder="$t('newRegister.inputEmailOrPhone')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="registerForm.code"
              autocomplete="off"
              maxlength="10"
              :placeholder="$t('newRegister.inputCode')"
              @keyup.enter.native="handleRegister"
            >
              <div
                slot="suffix"
                class="el-input__icon input-icon phone-code"
              >
                <div
                  class="phone-code-img"
                  v-loading="codeLoding"
                  @click="sendCode"
                  v-if="!isDisabled"
                >{{ content }}
                </div>
                <div
                  class="phone-code-img-disabled"
                  v-if="isDisabled"
                >{{ content }}</div>
              </div>
            </el-input>
          </el-form-item>
          <el-button
            :loading="loading"
            class="login-btn"
            @click.native.prevent="handleRegister"
          >
            <span v-if="!loading">{{ $t('register.submitRegister') }}</span>
            <span v-else>{{ $t('register.registering') }}</span>
          </el-button>
          <el-form-item
            prop="article"
            style="margin-top: 12px;"
          >
            <div class="flex-row-center mt10">
              <input
                type="checkbox"
                v-model="registerForm.article"
              />
              <span
                class="flex-row-center f-wrap ml8"
                style="line-height: 20px"
              >
                {{$t('agreed.readAndAgree')}}
                <el-link
                  type="primary"
                  :underline="false"
                  class="ml5 mr5"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/service"
                  target="_blank"
                >{{$t('agreed.userAgreement')}}</el-link>
                <span class="mr5">
                  {{$t('agreed.and')}}
                </span>
                <el-link
                  type="primary"
                  :underline="false"
                  style="vertical-align: middle;color:#194BFB;"
                  href="/privacy"
                  target="_blank"
                >{{$t('agreed.privacyPolicy')}}</el-link>
              </span>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="el-login-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div>
    <SlideVerifyDialog
      :showSlide.sync="showSlide"
      @success="onSuccess"
      @fail="onFail"
    />
  </div>
</template>

<script>
import { register } from "@/api/login";
import { sendEmilCode, sendMyPhone } from "@/api/base/resource";
import SlideVerifyDialog from "../components/dialog/SlideVerifyDialog.vue";

export default {
  name: "Register",
  components: {
    SlideVerifyDialog,
  },
  data() {
    return {
      isOnLoad: false, // 图片是否加载完成
      isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
      content: this.$t("newRegister.getEmailOrPhoneCode"),
      timer: null,
      count: "",
      codeType: "",
      registerForm: {
        // 注册请求体
        email: "",
        article: false,
        username: "",
        password: "",
        code: "",
        account: "",
        againPassword: "",
      },
      registerRules: {
        article: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error(this.$t("agreed.prompt")));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
        account: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("newRegister.tipAccount"),
          },
        ],
        email: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("login.tipEmail"),
          },
          { validator: this.validateEmailOrPhone, trigger: ["blur", "change"] },
          // {
          //   pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          //   message: this.$t('login.tipCorrectEmail'),
          //   trigger: ['blur', 'change']
          // },
        ],
        password: [
          {
            required: true,
            message: this.$t("register.passwordEmpty"),
            trigger: ["blur", "change"],
          },
          {
            pattern:
              /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/])[0-9a-zA-Z~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]{8,18}$/,
            message: this.$t("register.passwordCriteria"),
            trigger: "blur",
          },
        ],
        againPassword: [
          {
            required: true,
            message: this.$t("register.passwordEmpty"),
            trigger: ["blur", "change"],
          },
          { validator: this.validateAgainPassword, trigger: "blur" },
        ],
        code: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("register.enterVerificationCode"),
          },
        ],
      },
      loading: false,
      captchaEnabled: true,
      codeLoding: false,
      showSlide: false,
      showPassword: false, // 用于切换密码的显示/隐藏状态
      showAgainPassword: false,
    };
  },
  created() {},
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // 切换密码显示状态
    },
    toggleAgainPasswordVisibility() {
      this.showAgainPassword = !this.showAgainPassword; // 切换密码显示状态
    },
    onImageLoad() {
      // 图片加载完成后，设置isOnLoad为true
      this.isOnLoad = true;
    },
    onSuccess(times) {
      this.showSlide = false;
      this.getCodeClick();
    },
    onFail() {},
    // 发送验证码
    sendCode() {
      const emailOrPhone = this.registerForm.email;
      if (!this.codeType || !emailOrPhone) {
        this.$refs.registerForm.validateField("email");
        return;
      }
      const isValidEmail =
        /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          emailOrPhone
        );
      const isValidPhone = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(emailOrPhone);
      if (
        (this.codeType === "email" && !isValidEmail) ||
        (this.codeType === "phone" && !isValidPhone)
      ) {
        this.$refs.registerForm.validateField("email");
        return;
      }
      this.showSlide = true;
    },

    getCodeClick() {
      if (this.codeType === "email") {
        this.sendEmail();
      } else if (this.codeType === "phone") {
        this.sendPhone();
      }
    },
    validateEmailOrPhone(rule, value, callback) {
      // 邮箱正则表达式
      const emailPattern =
        /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      // 手机号正则表达式 (中国手机号格式)
      const phonePattern = /^1[3-9]\d{9}$/;

      // 校验邮箱或手机号
      if (emailPattern.test(value)) {
        this.codeType = "email"; // 如果是邮箱，设置 inputType 为 'email'
        callback();
      } else if (phonePattern.test(value)) {
        this.codeType = "phone"; // 如果是手机号，设置 inputType 为 'phone'
        callback();
      } else {
        callback(new Error(this.$t("newRegister.tipCorrectEmailOrPhone")));
      }
    },
    validateAgainPassword(rule, value, callback) {
      // 检查再次输入的密码是否与前一次的密码一致
      if (value !== this.registerForm.password) {
        callback(new Error(this.$t("newRegister.passwordMismatch")));
      } else {
        callback(); // 校验通过
      }
    },
    // 发送邮箱验证码
    sendEmail() {
      let vm = this;
      vm.content = "";
      this.codeLoding = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendEmilCode({
        sendType: "register",
        email: vm.registerForm.email,
        // uuid: vm.registerForm.uuid,
        // code: vm.registerForm.imgCode,
      })
        .then((res) => {
          if (res.code === 200) {
            this.codeLoding = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.content = this.$t("login.getSMSCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.codeLoding = false;
          vm.content = this.$t("login.getSMSCode");
        });
    },
    // 发送手机验证码
    sendPhone() {
      let vm = this;
      vm.content = "";
      this.codeLoding = true;
      // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
      sendMyPhone({
        sendType: "register",
        // phone: vm.registerForm.phone,
        phone: vm.registerForm.email,
        // uuid: vm.registerForm.uuid,
        // code: vm.registerForm.imgCode,
      })
        .then((res) => {
          if (res.code === 200) {
            this.codeLoding = false;
            vm.isDisabled = false;
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60;
            vm.count = TIME_COUNT;
            vm.content = vm.count + this.$t("login.later");
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true;
                // 计时秒数
                vm.count--;
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t("login.later");
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false;
                // 更新按钮文字内容
                vm.content = this.$t("login.getSMSCode");
                // 清空定时器!!!
                clearInterval(vm.timer);
                vm.timer = null;
              }
            }, 1000);
          }
        })
        .catch(() => {
          this.codeLoding = false;
          vm.content = this.$t("login.getSMSCode");
        });
    },
    handleRegister() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          if (!this.registerForm.article) {
            this.$modal.msgWarning(this.$t("register.acceptTerms"));
            return;
          }
          this.loading = true;
          this.registerForm.username = this.registerForm.email;
          register({ ...this.registerForm, registerType: this.codeType })
            .then((res) => {
              this.$modal.msgSuccess(
                this.$t("quickSignUp.registrationSuccess")
              );
              this.$router.push("/login");
              // const username = this.registerForm.username;
              // this.$alert(
              //   "<font color='red'>" + this.$t('register.registrationReceived') + "</font>",
              //   this.$t('register.systemPrompt'),
              //   {
              //     dangerouslyUseHTMLString: true,
              //     type: "success",
              //   }
              // )
              //   .then(() => {
              //     this.$router.push("/login");
              //   })
              //   .catch(() => {
              //   });
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.register-form {
  padding: 0 66px;

  ::v-deep .el-input--medium .el-input__inner {
    height: 46px;
    line-height: 46px;
    background: #eff6fd;
    border-radius: 14px;
    border: 1px solid #b9c4d5;
  }
  ::v-deep .el-form-item {
    margin-bottom: 24px;
  }
  ::v-deep .el-form-item__error {
    line-height: 0.8;
  }
}
.register-title {
  height: 34px;
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  line-height: 34px;
  letter-spacing: 1px;
  text-align: left;
  font-style: normal;
  margin-top: 40px;
}
.go-login {
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin: 16px 0 32px;
}
.passwrod-eye {
  width: 52px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  .iconfont {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
}
@media screen and (max-height: 900px) {
  .register-form {
    padding: 0 66px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 46px;
      line-height: 46px;
      background: #eff6fd;
      border-radius: 14px;
      border: 1px solid #b9c4d5;
    }
    ::v-deep .el-form-item {
      margin-bottom: 24px;
    }
  }
  .register-title {
    height: 34px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 30px;
    color: #000000;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-top: 40px;
  }
  .go-login {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 16px 0 32px;
  }
  .passwrod-eye {
    width: 52px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 20px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }
}
@media screen and (max-height: 768px) {
  .register-form {
    padding: 0 35px;

    ::v-deep .el-input--medium .el-input__inner {
      height: 40px;
      line-height: 40px;
      background: #eff6fd;
      border-radius: 10px;
      border: 1px solid #b9c4d5;
    }
    ::v-deep .el-form-item {
      margin-bottom: 24px;
    }
  }
  .register-title {
    height: 34px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    line-height: 34px;
    letter-spacing: 1px;
    text-align: left;
    font-style: normal;
    margin-top: 32px;
  }
  .go-login {
    height: 20px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin: 10px 0 24px;
  }
  .passwrod-eye {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
    }
  }
}
</style>
