<template>
  <div class="register">
    <div class="register-main">
      <h3 class="flex-center title mt20">{{ $t('register.register') }}</h3>
      <el-steps :active="active" simple finish-status="success" style="margin: 0 20% 20px">
        <el-step :title="$t('register.basicInfo')"></el-step>
        <el-step :title="$t('register.setupAccount')"></el-step>
      </el-steps>
      <el-form
        ref="registerForm"
        :model="registerForm"
        :rules="registerRules"
        label-position="right"
        label-width="100px"
        class="register-form"
      >
      <div v-if="active === 0">
        <el-form-item :label="$t('register.companyName')" prop="companyName">
          <el-input
            v-model="registerForm.companyName"
            type="text"
            autocomplete="off"
            :placeholder="$t('tip.input')"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('register.industry')" prop="industryCategory" style="margin-bottom:20px">
          <el-cascader
            style="width: 100%;"
            :show-all-levels="false"
            :props="{value:'name',label:'name',emitPath: false}"
            v-model="registerForm.industryCategory"
            :options="industryCategoryOptions"
          ></el-cascader>
        </el-form-item>
        <el-form-item :label="$t('register.brand')" prop="brand">
          <el-input
            v-model="registerForm.brand"
            type="text"
            autocomplete="off"
            :placeholder="$t('tip.input')"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('register.product')" prop="product">
          <el-input
            v-model="registerForm.product"
            type="text"
            autocomplete="off"
            :placeholder="$t('tip.input')"
          >
          </el-input>
        </el-form-item>
        <div class="flex-center">
          <el-button  @click="nextStep" type="primary" style="width:50%">{{ $t('register.next') }}</el-button>
        </div>
      </div>
      <div v-else>
        <el-form-item :label="$t('register.registerType')" prop="registerType">
          <el-radio-group v-model="registerForm.registerType">
            <el-radio :label="'email'">{{ $t('register.emailRegister') }}</el-radio>
            <el-radio :label="'phone'">{{ $t('register.phoneRegister') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('login.email')" prop="email" v-if="registerForm.registerType === 'email'">
          <el-input
            v-model="registerForm.email"
            type="text"
            autocomplete="off"
            :placeholder="$t('login.email')"
          >
            <svg-icon slot="prefix" icon-class="email" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <el-form-item v-else :label="$t('login.mobile')"  prop="phone">
          <el-input
            v-model="registerForm.phone"
            type="text"
            autocomplete="off"
            :placeholder="$t('login.mobile')"
          >
            <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon"/>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('register.password')" prop="password">
          <el-input
            v-model="registerForm.password"
            type="password"
            show-password
            autocomplete="off"
            :placeholder="$t('register.passwordHint')"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon
              slot="prefix"
              icon-class="password"
              class="el-input__icon input-icon"
            />
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('register.imageCaptcha')" prop="imgCode">
          <el-input
            v-model="registerForm.imgCode"
            autocomplete="off"
            :placeholder="$t('login.imageVerificationCode')"
            style="width: 60%"
          >
            <svg-icon
              slot="prefix"
              icon-class="validCode"
              class="el-input__icon input-icon"
            />
          </el-input>
          <div class="register-code" style="cursor : pointer;">
            <img :src="codeUrl" @click="getCode" style="height: 38px;"/>
          </div>
        </el-form-item>
        <el-form-item :label="$t('register.verificationCode')" prop="code">
          <el-input
            v-model="registerForm.code"
            autocomplete="off"
            :placeholder="$t('login.verificationCode')"
            style="width: 63%"
            @keyup.enter.native="handleRegister"
          >
            <svg-icon
              slot="prefix"
              icon-class="validCode"
              class="el-input__icon input-icon"
            />
          </el-input>
          <span v-if="registerForm.registerType === 'email'">
            <el-button :loading="emailLoading" style="margin-left: 10px" class="btn round" @click="sendEmail()"
                       v-if="!isDisabled">{{ content }}
            </el-button>
            <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ content }}</el-button>
          </span>
          <span v-else>
            <el-button :loading="phoneLoading" style="margin-left: 10px" class="btn round" @click="sendPhone()"
                       v-if="!isDisabled">{{ phoneContent }}
            </el-button>
            <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ phoneContent }}</el-button>
          </span>
        </el-form-item>
        <el-form-item prop="article">
          <el-checkbox v-model="registerForm.article"></el-checkbox>&nbsp;{{$t('agreed.readAndAgree')}}
          <a style="color: #00afff" href="/service" target="_blank">{{$t('agreed.userAgreement')}}</a>
          {{$t('agreed.and')}}
          <a style="color: #00afff" href="/privacy" target="_blank">{{$t('agreed.privacyPolicy')}}</a>
        </el-form-item>
        <div class="flex-center">
          <el-button  @click="lastStep" style="width:30%">{{ $t('register.previous') }}</el-button>
          <el-button
            :disabled="!registerForm.article"
            :loading="loading"
            size="medium"
            type="primary"
            style="width: 30%"
            @click.native.prevent="handleRegister"
          >
            <span v-if="!loading">{{ $t('register.submitRegister') }}</span>
            <span v-else>{{ $t('register.registering') }}</span>
          </el-button>

        </div>
      </div>
      <el-form-item style="width: 100%">
      </el-form-item>
      <div style="float: right">
        <router-link class="link-type" :to="'/login'">{{ $t('register.useExistingAccount') }}</router-link>
      </div>
      </el-form>
    </div>
    <!-- <div class="bk" style="float:right;"></div> -->
    <!--  底部  -->
    <!-- <div class="el-register-footer">
      <span>Copyright © 2022-2025 Infonity AI </span>
    </div> -->
  </div>
</template>

<script>
  import {getCodeImg, register} from "@/api/login";
  import {sendEmilCode, sendMyPhone} from "@/api/base/resource";
import { industryCategoryTreeSelect } from "../api/base/resource";

  export default {
    name: "Register",
    data() {
      return {
        active: 0,
        industryCategoryOptions: undefined, // 行业分类
        baseUrl: '',
        isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
        content: this.$t('login.getEmailCode'),
        phoneContent: this.$t('login.getSMSCode'),
        timer: null,
        count: '',
        codeUrl: "",
        registerForm: { // 注册请求体
          companyName: '',
          industryCategory: '',
          brand: '',
          product: '',
          email:'',
          phone:'',
          article: false,
          username: "",
          password: "",
          code: "",
          imgCode: "",
          uuid: "",
          registerType: "email",
        },
        registerRules: {
          companyName: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterCompanyName')}],
          industryCategory: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.selectIndustry')}],
          brand: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterBrandName')}],
          product: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterProduct')}],
          email: [
            {required: true, trigger: ['blur', 'change'], message: this.$t('login.tipEmail')},
            {
              pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
              message: this.$t('login.tipCorrectEmail'),
              trigger: ['blur', 'change']
            },
          ],
          phone: [
            {required: true, trigger: ['blur', 'change'], message: this.$t('login.tipPhone')},
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: this.$t('login.tipCorrectPhone'),
              trigger: ['blur', 'change']
            }
          ],
          password: [
            {required: true, message: this.$t('register.passwordEmpty'), trigger: ['blur', 'change']},
            {
              pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/])[0-9a-zA-Z~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]{8,18}$/,
              message: this.$t('register.passwordCriteria'),
              trigger: "blur",
            },
          ],
          code: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterVerificationCode')}],
          imgCode: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterImageCaptchaAgain')}],
        },
        loading: false,
        emailLoading: false,
        phoneLoading: false,
        captchaEnabled: true,
      };
    },
    watch:{
      'registerForm.registerType':function(){
        this.$nextTick(() => {
          this.$refs.registerForm.clearValidate();
        });
      },
      active(newVal,oldVal){
        this.$nextTick(() => {
          this.$refs.registerForm.clearValidate();
        });
      }
    },
    created() {
      this.baseUrl = location.origin
      this.getCode()
      this.getIndustryCategoryOptionsTree()
    },
    methods: {
      nextStep(){
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            this.active ++
          }
        })
      },
      lastStep(){
        this.active --
      },
      /** 所属行业下拉树结构 */
      getIndustryCategoryOptionsTree() {
        industryCategoryTreeSelect().then((response) => {
          this.industryCategoryOptions = response.data;
        });
      },
      getCode() {
        getCodeImg().then((res) => {
          this.captchaEnabled =
            res.data.captchaEnabled === undefined ? true : res.data.captchaEnabled;
          if (this.captchaEnabled) {
            this.codeUrl = "data:image/gif;base64," + res.data.img;
            this.registerForm.uuid = res.data.uuid;
          }
        });
      },
      // 发送邮箱验证码
      sendEmail() {
        if (this.registerForm.email === '') {
          this.$refs.registerForm.validateField('email')
          return
        } else {
          if (!/^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.registerForm.email)) {
            this.$refs.registerForm.validateField('email')
            return
          } else if (this.registerForm.imgCode == null) {
            this.$refs.registerForm.validateField('imgCode')
            return
          }
        }
        let vm = this
        vm.content = ''
        this.emailLoading = true
        // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
        sendEmilCode({
          sendType: 'register',
          email: vm.registerForm.email,
          uuid: vm.registerForm.uuid,
          code: vm.registerForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.emailLoading = false
            vm.isDisabled = false
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60
            vm.count = TIME_COUNT
            vm.content = vm.count + this.$t('login.later')
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true
                // 计时秒数
                vm.count--
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t('login.later')
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false
                // 更新按钮文字内容
                vm.content = this.$t('login.getEmailCode')
                // 清空定时器!!!
                clearInterval(vm.timer)
                vm.timer = null
              }
            }, 1000)
          }
        }).catch(() => {
          this.emailLoading = false
          vm.content = this.$t('login.getEmailCode')
          this.getCode()
        })
      },
      // 发送手机验证码
      sendPhone() {
        if (this.registerForm.phone === '') {
          this.$refs.registerForm.validateField('phone')
          return
        }
        if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.registerForm.phone)) {
          this.$refs.registerForm.validateField('phone')
          return
        }
        if (this.registerForm.imgCode == null) {
          this.$refs.registerForm.validateField('imgCode')
          return
        }
        let vm = this
        vm.phoneContent = ''
        this.phoneLoading = true
        // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
        sendMyPhone({
          sendType: 'register',
          phone: vm.registerForm.phone,
          uuid: vm.registerForm.uuid,
          code: vm.registerForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.phoneLoading = false
            vm.isDisabled = false
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60
            vm.count = TIME_COUNT
            vm.phoneContent = vm.count + this.$t('login.later')
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true
                // 计时秒数
                vm.count--
                // 更新按钮的文字内容
                vm.phoneContent = vm.count + this.$t('login.later')
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false
                // 更新按钮文字内容
                vm.phoneContent = this.$t('login.getSMSCode')
                // 清空定时器!!!
                clearInterval(vm.timer)
                vm.timer = null
              }
            }, 1000)
          }
        }).catch(() => {
          this.phoneLoading = false
          vm.phoneContent = this.$t('login.getSMSCode')
          this.getCode()
        })
      },
      handleRegister() {
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            if (!this.registerForm.article) {
              this.$modal.msgWarning(this.$t('register.acceptTerms'))
              return
            }
            this.loading = true;
            this.registerForm.username = this.registerForm.registerType === 'email' ? this.registerForm.email : this.registerForm.phone
            register(this.registerForm)
              .then((res) => {
                const username = this.registerForm.username;
                this.$alert(
                  "<font color='red'>" + this.$t('register.registrationReceived') + "</font>",
                  this.$t('register.systemPrompt'),
                  {
                    dangerouslyUseHTMLString: true,
                    type: "success",
                  }
                )
                  .then(() => {
                    this.$router.push("/login");
                  })
                  .catch(() => {
                  });
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
      },
    }
    ,
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .bk {
    background-image: url("../assets/images/loginBackground.png");
    //background-size: 100% 100%;
    background-size: cover; /* 覆盖整个元素，可能裁剪部分图片 */
    background-position: center; /* 图片在元素中央显示 */
    width: 60%;
    height: 100vh; /* 100% 屏幕高度 */
  }

  .register {
    height: 100%;
    // display: flex;
    // justify-content: flex-end;
    // align-items: center;
    background-color: #F7F8FA;
  }

  .register-main {
    width: 100%;
    background-color: #F7F8FA;
    padding-top: 30px;
  }

  .title {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    color: #1D2129;
    line-height: 48px;
    // margin: 0 20px 30px auto;
  }

  .register-form {
    width: 100%;
    padding: 0 20% 30px;

    // .el-input {
    //   height: 38px;

    //   input {
    //     height: 38px;
    //   }
    // }

    // .input-icon {
    //   height: 39px;
    //   width: 14px;
    //   margin-left: 2px;
    // }
  }

  .register-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .register-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-register-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: black;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>
