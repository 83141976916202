<template>
  <div class="comment-item">
    <div class="flex-row">
      <el-avatar
        :size="44"
        :src="require('@/assets/images/saleAi.png')"
        class="comment-ai-avatar f-shrink"
      />
      <div class="f-grow">
        <div class="flex-row-center f-between">
          <span class="comment-ai">{{ ai }}</span>
          <span class="comment-time">{{ comment.aiReplyTime }}</span>
        </div>
        <div class="flex-row f-wrap comment-content">
          <span>{{ comment.aiReplyContent }}</span>
          <span class="comment-username">//@{{ comment.username }}：</span>
          <span>{{ comment.comment }}</span>
        </div>
        <div class="video-theme">
          <div class="flex-row-center f-between">
            <span class="comment-ai">{{ $t('sale.videoTopic') }}</span>
            <span class="comment-time">{{ $t('sale.publishedOn') }}：{{ comment.publishTime }}</span>
          </div>
          <div class="comment-content">
            <span>{{ comment.postContent }}</span>
          </div>
          <video
            v-if="comment.url"
            :src="comment.url"
            controls
            alt="video"
            class="video-theme-video"
            ref="videoPlayer"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommentItem",
  props: {
    ai: {
      type: String,
      default: () => "AI",
    },
    avatar: {
      type: String,
      default: () =>
        "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
    },
    comment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.comment-item {
  width: 100%;
  margin-top: 16px;
  .comment-ai-avatar {
    border: 1px solid #dcdcdc;
    margin-right: 12px;
  }
  .comment-ai {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 20px;
    text-align: left;
    font-style: normal;
  }
  .comment-time {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 18px;
    font-style: normal;
  }
  .comment-content {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #293951;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap; /* 允许换行 */
    gap: 4px; /* 添加元素间距 */
  }
  .comment-content span {
    white-space: normal; /* 默认允许换行 */
    word-wrap: break-word; /* 超过宽度时换行 */
  }
  .comment-username {
    color: #0156ff;
  }
  .video-theme {
    background: #e7eef5;
    border-radius: 10px;
    margin-top: 8px;
    padding: 16px;
    .video-theme-video {
      width: 200px;
      height: 120px;
      border-radius: 16px;
      margin-top: 8px;
      background: black;
      cursor: pointer;
      object-fit: cover; /* 确保视频不会拉伸 */
    }
  }
}
</style>
