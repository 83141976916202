var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-container flex-center" },
    [
      _c("div", { staticClass: "login-main flex-row" }, [
        _c(
          "div",
          { staticClass: "login-bg-50 login-left-radius flex-column-center" },
          [
            _c("img", {
              staticClass: "login-img-left login-left-radius",
              attrs: {
                src: require("@/assets/images/login/bg-small.jpg"),
                alt: "",
              },
              on: { load: _vm.onImageLoad },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOnLoad,
                    expression: "isOnLoad",
                  },
                ],
                staticClass: "left-logo",
              },
              [_vm._v("InfoPub")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOnLoad,
                    expression: "isOnLoad",
                  },
                ],
                staticClass: "left-des f-grow",
              },
              [_vm._v(_vm._s(_vm.$t("login.smartPublishSystem")))]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isOnLoad,
                    expression: "isOnLoad",
                  },
                ],
                staticClass: "left-bottom",
              },
              [_vm._v(_vm._s(_vm.$t("login.oneClickPublish")))]
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "login-bg-50 login-right-radius flex-column" },
          [
            _c(
              "el-form",
              {
                ref: "registerForm",
                staticClass: "register-form",
                attrs: {
                  model: _vm.registerForm,
                  rules: _vm.registerRules,
                  "label-position": "right",
                  "label-width": "0",
                },
              },
              [
                _c("div", { staticClass: "register-title" }, [
                  _vm._v(_vm._s(_vm.$t("newRegister.registerUser"))),
                ]),
                _c(
                  "div",
                  { staticClass: "go-login" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("newRegister.alreadyRegistered")) + " "
                    ),
                    _c(
                      "router-link",
                      { staticClass: "link-type", attrs: { to: "/login" } },
                      [_vm._v(_vm._s(_vm.$t("newRegister.login")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "account" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        maxlength: "50",
                        placeholder: _vm.$t("newRegister.setUsername"),
                      },
                      model: {
                        value: _vm.registerForm.account,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerForm, "account", $$v)
                        },
                        expression: "registerForm.account",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.showPassword ? "text" : "password",
                          autocomplete: "off",
                          maxlength: "18",
                          placeholder: _vm.$t("newRegister.setPassword"),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleRegister($event)
                          },
                        },
                        model: {
                          value: _vm.registerForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.registerForm, "password", $$v)
                          },
                          expression: "registerForm.password",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "el-input__icon input-icon passwrod-eye",
                            attrs: { slot: "suffix" },
                            slot: "suffix",
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont",
                              class: _vm.showPassword
                                ? "icon-zhengyan"
                                : "icon-biyan",
                              on: { click: _vm.togglePasswordVisibility },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "againPassword" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          type: _vm.showAgainPassword ? "text" : "password",
                          autocomplete: "off",
                          maxlength: "18",
                          placeholder: _vm.$t("newRegister.againPassword"),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleRegister($event)
                          },
                        },
                        model: {
                          value: _vm.registerForm.againPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.registerForm, "againPassword", $$v)
                          },
                          expression: "registerForm.againPassword",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "el-input__icon input-icon passwrod-eye",
                            attrs: { slot: "suffix" },
                            slot: "suffix",
                          },
                          [
                            _c("i", {
                              staticClass: "iconfont",
                              class: _vm.showAgainPassword
                                ? "icon-zhengyan"
                                : "icon-biyan",
                              on: { click: _vm.toggleAgainPasswordVisibility },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "email" } },
                  [
                    _c("el-input", {
                      attrs: {
                        type: "text",
                        autocomplete: "off",
                        maxlength: "50",
                        placeholder: _vm.$t("newRegister.inputEmailOrPhone"),
                      },
                      model: {
                        value: _vm.registerForm.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.registerForm, "email", $$v)
                        },
                        expression: "registerForm.email",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { prop: "code" } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          autocomplete: "off",
                          maxlength: "10",
                          placeholder: _vm.$t("newRegister.inputCode"),
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleRegister($event)
                          },
                        },
                        model: {
                          value: _vm.registerForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.registerForm, "code", $$v)
                          },
                          expression: "registerForm.code",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "el-input__icon input-icon phone-code",
                            attrs: { slot: "suffix" },
                            slot: "suffix",
                          },
                          [
                            !_vm.isDisabled
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.codeLoding,
                                        expression: "codeLoding",
                                      },
                                    ],
                                    staticClass: "phone-code-img",
                                    on: { click: _vm.sendCode },
                                  },
                                  [_vm._v(_vm._s(_vm.content) + " ")]
                                )
                              : _vm._e(),
                            _vm.isDisabled
                              ? _c(
                                  "div",
                                  { staticClass: "phone-code-img-disabled" },
                                  [_vm._v(_vm._s(_vm.content))]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    attrs: { loading: _vm.loading },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleRegister($event)
                      },
                    },
                  },
                  [
                    !_vm.loading
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.$t("register.submitRegister"))),
                        ])
                      : _c("span", [
                          _vm._v(_vm._s(_vm.$t("register.registering"))),
                        ]),
                  ]
                ),
                _c(
                  "el-form-item",
                  {
                    staticStyle: { "margin-top": "12px" },
                    attrs: { prop: "article" },
                  },
                  [
                    _c("div", { staticClass: "flex-row-center mt10" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.article,
                            expression: "registerForm.article",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.registerForm.article)
                            ? _vm._i(_vm.registerForm.article, null) > -1
                            : _vm.registerForm.article,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.registerForm.article,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.registerForm,
                                    "article",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.registerForm,
                                    "article",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.registerForm, "article", $$c)
                            }
                          },
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "flex-row-center f-wrap ml8",
                          staticStyle: { "line-height": "20px" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("agreed.readAndAgree")) + " "
                          ),
                          _c(
                            "el-link",
                            {
                              staticClass: "ml5 mr5",
                              staticStyle: {
                                "vertical-align": "middle",
                                color: "#194BFB",
                              },
                              attrs: {
                                type: "primary",
                                underline: false,
                                href: "/service",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("agreed.userAgreement")))]
                          ),
                          _c("span", { staticClass: "mr5" }, [
                            _vm._v(" " + _vm._s(_vm.$t("agreed.and")) + " "),
                          ]),
                          _c(
                            "el-link",
                            {
                              staticStyle: {
                                "vertical-align": "middle",
                                color: "#194BFB",
                              },
                              attrs: {
                                type: "primary",
                                underline: false,
                                href: "/privacy",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("agreed.privacyPolicy")))]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._m(0),
      _c("SlideVerifyDialog", {
        attrs: { showSlide: _vm.showSlide },
        on: {
          "update:showSlide": function ($event) {
            _vm.showSlide = $event
          },
          "update:show-slide": function ($event) {
            _vm.showSlide = $event
          },
          success: _vm.onSuccess,
          fail: _vm.onFail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [_vm._v("Copyright © 2022-2025 Infonity AI ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }