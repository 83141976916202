<template>
  <div class="register">
    <div class="register-main">
      <h3 class="flex-center title mt20">{{ $t('register.register') }}</h3>
      <el-form
        ref="registerForm"
        :model="registerForm"
        :rules="registerRules"
        label-position="right"
        label-width="140px"
        class="register-form"
      >
      <el-form-item :label="$t('newRegister.account')" prop="account" v-if="registerForm.registerType === 'email'">
        <el-input
          v-model="registerForm.account"
          type="text"
          autocomplete="off"
          :placeholder="$t('newRegister.account')"
        >
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('register.password')" prop="password">
        <el-input
          v-model="registerForm.password"
          type="password"
          show-password
          autocomplete="off"
          :placeholder="$t('register.passwordHint')"
          @keyup.enter.native="handleRegister"
        >
          <svg-icon
            slot="prefix"
            icon-class="password"
            class="el-input__icon input-icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('newRegister.againPassword')" prop="againPassword">
        <el-input
          v-model="registerForm.againPassword"
          type="password"
          show-password
          autocomplete="off"
          :placeholder="$t('newRegister.againPassword')"
          @keyup.enter.native="handleRegister"
        >
          <svg-icon
            slot="prefix"
            icon-class="password"
            class="el-input__icon input-icon"
          />
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('newRegister.emailOrPhone')" prop="email" v-if="registerForm.registerType === 'email'">
        <el-input
          v-model="registerForm.email"
          type="text"
          autocomplete="off"
          :placeholder="$t('newRegister.emailOrPhone')"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-else :label="$t('login.mobile')"  prop="phone">
        <el-input
          v-model="registerForm.phone"
          type="text"
          autocomplete="off"
          :placeholder="$t('login.mobile')"
        >
          <svg-icon slot="prefix" icon-class="phone" class="el-input__icon input-icon"/>
        </el-input>
      </el-form-item>
      <el-form-item :label="$t('register.imageCaptcha')" prop="imgCode">
        <el-input
          v-model="registerForm.imgCode"
          autocomplete="off"
          :placeholder="$t('login.imageVerificationCode')"
          style="width: 60%"
        >
          <svg-icon
            slot="prefix"
            icon-class="validCode"
            class="el-input__icon input-icon"
          />
        </el-input>
        <div class="register-code" style="cursor : pointer;">
          <img :src="codeUrl" @click="getCode" style="height: 38px;"/>
        </div>
      </el-form-item>
      <el-form-item :label="$t('newRegister.verificationCode')" prop="code">
        <el-input
          v-model="registerForm.code"
          autocomplete="off"
          :placeholder="$t('newRegister.verificationCode')"
          style="width: 63%"
          @keyup.enter.native="handleRegister"
        >
          <svg-icon
            slot="prefix"
            icon-class="validCode"
            class="el-input__icon input-icon"
          />
        </el-input>
        <el-button :loading="codeLoding" style="margin-left: 10px" class="btn round" @click="getCodeClick()"
                     v-if="!isDisabled">{{ content }}
        </el-button>
        <el-button style="margin-left: 10px" class="btn round wait" v-if="isDisabled">{{ content }}</el-button>
      </el-form-item>
      <el-form-item prop="article">
        <el-checkbox v-model="registerForm.article"></el-checkbox>&nbsp;{{$t('agreed.readAndAgree')}}
        <a style="color: #00afff" href="/service" target="_blank">{{$t('agreed.userAgreement')}}</a>
        {{$t('agreed.and')}}
        <a style="color: #00afff" href="/privacy" target="_blank">{{$t('agreed.privacyPolicy')}}</a>
      </el-form-item>
      <div class="flex-center">
        <el-button
          :disabled="!registerForm.article"
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 60%"
          @click.native.prevent="handleRegister"
        >
          <span v-if="!loading">{{ $t('register.submitRegister') }}</span>
          <span v-else>{{ $t('register.registering') }}</span>
        </el-button>

      </div>
      <div class="flex-center mt10">
        <router-link class="link-type" :to="'/login'">{{ $t('register.useExistingAccount') }}</router-link>
      </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {getCodeImg, register} from "@/api/login";
  import {sendEmilCode, sendMyPhone} from "@/api/base/resource";

  export default {
    name: "Register",
    data() {
      return {
        isDisabled: false, //控制按钮是否可以点击（false:可以点击，true:不可点击）
        content: this.$t('newRegister.getEmailOrPhoneCode'),
        timer: null,
        count: '',
        codeUrl: "",
        codeType: '',
        registerForm: { // 注册请求体
          email:'',
          phone:'',
          article: false,
          username: "",
          password: "",
          code: "",
          imgCode: "",
          uuid: "",
          registerType: "email",
          account: '',
          againPassword: '',
        },
        registerRules: {
          account: [{required: true, trigger: ['blur', 'change'], message: this.$t('newRegister.tipAccount')}],
          email: [
            {required: true, trigger: ['blur', 'change'], message: this.$t('login.tipEmail')},
            { validator: this.validateEmailOrPhone, trigger: ['blur', 'change'] },
            // {
            //   pattern: /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            //   message: this.$t('login.tipCorrectEmail'),
            //   trigger: ['blur', 'change']
            // },
          ],
          phone: [
            {required: true, trigger: ['blur', 'change'], message: this.$t('login.tipPhone')},
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: this.$t('login.tipCorrectPhone'),
              trigger: ['blur', 'change']
            }
          ],
          password: [
            {required: true, message: this.$t('register.passwordEmpty'), trigger: ['blur', 'change']},
            {
              pattern: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/])[0-9a-zA-Z~`!@#$%^&*()_\-+=\[\]{}|\\:;"'<>,.?/]{8,18}$/,
              message: this.$t('register.passwordCriteria'),
              trigger: "blur",
            },
          ],
          againPassword: [
          { required: true, message: this.$t('register.passwordEmpty'), trigger: ['blur', 'change'] },
          { validator: this.validateAgainPassword, trigger: 'blur' }
          ],
          code: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterVerificationCode')}],
          imgCode: [{required: true, trigger: ['blur', 'change'], message: this.$t('register.enterImageCaptchaAgain')}],
        },
        loading: false,
        captchaEnabled: true,
        codeLoding: false
      };
    },
    watch:{
      'registerForm.registerType':function(){
        this.$nextTick(() => {
          this.$refs.registerForm.clearValidate();
        });
      },
    },
    created() {
      this.getCode()
    },
    methods: {
      getCodeClick() {
        if (this.codeType === 'email') {
          this.sendEmail()
        } else if(this.codeType === 'phone') {
          this.sendPhone()
        }
      },
      validateEmailOrPhone(rule, value, callback) {
        // 邮箱正则表达式
        const emailPattern = /^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        // 手机号正则表达式 (中国手机号格式)
        const phonePattern = /^1[3-9]\d{9}$/;

        // 校验邮箱或手机号
        if (emailPattern.test(value)) {
          this.codeType = 'email'; // 如果是邮箱，设置 inputType 为 'email'
          callback();
        } else if (phonePattern.test(value)) {
          this.codeType = 'phone'; // 如果是手机号，设置 inputType 为 'phone'
          callback();
        } else {
          callback(new Error(this.$t('newRegister.tipCorrectEmailOrPhone')));
        }
      },
      validateAgainPassword(rule, value, callback) {
        // 检查再次输入的密码是否与前一次的密码一致
        if (value !== this.registerForm.password) {
          callback(new Error(this.$t('newRegister.passwordMismatch')));
        } else {
          callback(); // 校验通过
        }
      },
      getCode() {
        getCodeImg().then((res) => {
          this.captchaEnabled =
            res.data.captchaEnabled === undefined ? true : res.data.captchaEnabled;
          if (this.captchaEnabled) {
            this.codeUrl = "data:image/gif;base64," + res.data.img;
            this.registerForm.uuid = res.data.uuid;
          }
        });
      },
      // 发送邮箱验证码
      sendEmail() {
        if (this.registerForm.email === '') {
          this.$refs.registerForm.validateField('email')
          return
        } else {
          if (!/^[A-Za-z0-9_\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.registerForm.email)) {
            this.$refs.registerForm.validateField('email')
            return
          } else if (this.registerForm.imgCode == null) {
            this.$refs.registerForm.validateField('imgCode')
            return
          }
        }
        let vm = this
        vm.content = ''
        this.codeLoding = true
        // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
        sendEmilCode({
          sendType: 'register',
          email: vm.registerForm.email,
          uuid: vm.registerForm.uuid,
          code: vm.registerForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.codeLoding = false
            vm.isDisabled = false
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60
            vm.count = TIME_COUNT
            vm.content = vm.count + this.$t('login.later')
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true
                // 计时秒数
                vm.count--
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t('login.later')
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false
                // 更新按钮文字内容
                vm.content = this.$t('login.getEmailCode')
                // 清空定时器!!!
                clearInterval(vm.timer)
                vm.timer = null
              }
            }, 1000)
          }
        }).catch(() => {
          this.codeLoding = false
          vm.content = this.$t('login.getEmailCode')
          this.getCode()
        })
      },
      // 发送手机验证码
      sendPhone() {
        // if (this.registerForm.phone === '') {
        //   this.$refs.registerForm.validateField('phone')
        //   return
        // }
        // if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.registerForm.phone)) {
        //   this.$refs.registerForm.validateField('phone')
        //   return
        // }
        // if (this.registerForm.imgCode == null) {
        //   this.$refs.registerForm.validateField('imgCode')
        //   return
        // }
        if (this.registerForm.email === '') {
          this.$refs.registerForm.validateField('email')
          return
        }
        if (!/^1[3|4|5|6|7|8|9][0-9]\d{8}$/.test(this.registerForm.email)) {
          this.$refs.registerForm.validateField('email')
          return
        }
        if (this.registerForm.imgCode == null) {
          this.$refs.registerForm.validateField('imgCode')
          return
        }
        let vm = this
        vm.content = ''
        this.codeLoding = true
        // 请求后台发送验证码至手机（vm.model内存有username字段为用户手机号,下述方法是自己项目封装的api请求（import引入的，没在此代码块显示），可根据自己实际请求方式修改）
        sendMyPhone({
          sendType: 'register',
          // phone: vm.registerForm.phone,
          phone: vm.registerForm.email,
          uuid: vm.registerForm.uuid,
          code: vm.registerForm.imgCode
        }).then(res => {
          if (res.code === 200) {
            this.codeLoding = false
            vm.isDisabled = false
            // 控制倒计时及按钮是否可以点击
            const TIME_COUNT = 60
            vm.count = TIME_COUNT
            vm.content = vm.count + this.$t('login.later')
            vm.timer = window.setInterval(() => {
              if (vm.count > 0 && vm.count <= TIME_COUNT) {
                // 倒计时时不可点击
                vm.isDisabled = true
                // 计时秒数
                vm.count--
                // 更新按钮的文字内容
                vm.content = vm.count + this.$t('login.later')
              } else {
                // 倒计时完，可点击
                vm.isDisabled = false
                // 更新按钮文字内容
                vm.content = this.$t('login.getSMSCode')
                // 清空定时器!!!
                clearInterval(vm.timer)
                vm.timer = null
              }
            }, 1000)
          }
        }).catch(() => {
          this.codeLoding = false
          vm.content = this.$t('login.getSMSCode')
          this.getCode()
        })
      },
      handleRegister() {
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            if (!this.registerForm.article) {
              this.$modal.msgWarning(this.$t('register.acceptTerms'))
              return
            }
            this.loading = true;
            this.registerForm.username = this.registerForm.registerType === 'email' ? this.registerForm.email : this.registerForm.phone
            register({...this.registerForm, registerType: this.codeType})
              .then((res) => {
                this.$modal.msgSuccess(this.$t('quickSignUp.registrationSuccess'))
                this.$router.push("/login");
                // const username = this.registerForm.username;
                // this.$alert(
                //   "<font color='red'>" + this.$t('register.registrationReceived') + "</font>",
                //   this.$t('register.systemPrompt'),
                //   {
                //     dangerouslyUseHTMLString: true,
                //     type: "success",
                //   }
                // )
                //   .then(() => {
                //     this.$router.push("/login");
                //   })
                //   .catch(() => {
                //   });
              })
              .catch(() => {
                this.loading = false;
              });
          }
        });
      },
    }
    ,
  }
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .bk {
    background-image: url("../assets/images/loginBackground.png");
    //background-size: 100% 100%;
    background-size: cover; /* 覆盖整个元素，可能裁剪部分图片 */
    background-position: center; /* 图片在元素中央显示 */
    width: 60%;
    height: 100vh; /* 100% 屏幕高度 */
  }

  .register {
    height: 100%;
    // display: flex;
    // justify-content: flex-end;
    // align-items: center;
    background-color: #F7F8FA;
  }

  .register-main {
    width: 100%;
    background-color: #F7F8FA;
    padding-top: 30px;
  }

  .title {
    cursor: pointer;
    font-size: 22px;
    font-weight: 600;
    color: #1D2129;
    line-height: 48px;
    // margin: 0 20px 30px auto;
  }

  .register-form {
    width: 100%;
    padding: 0 20% 30px;

    // .el-input {
    //   height: 38px;

    //   input {
    //     height: 38px;
    //   }
    // }

    // .input-icon {
    //   height: 39px;
    //   width: 14px;
    //   margin-left: 2px;
    // }
  }

  .register-tip {
    font-size: 13px;
    text-align: center;
    color: #bfbfbf;
  }

  .register-code {
    width: 33%;
    height: 38px;
    float: right;

    img {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .el-register-footer {
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: black;
    font-family: Arial;
    font-size: 12px;
    letter-spacing: 1px;
  }
</style>
