<template>
  <div class="pricing-page flex-column-center">
    <img
      class="package-title"
      src="../assets/images/package/package-title.png"
      alt=""
    />
    <!-- 套餐价格区域 -->
    <div class="pricing-container width1280">
      <div class="line-head"></div>
      <div class="flex-row">
        <div
          class="w-25 border-right"
          v-for="(item, index) in packageList"
          :key="index"
        >
          <div class="base-head">
            <span class="package-name">{{ item.name }}</span>
            <span class="package-desc">{{ item.desc }}</span>
            <div
              v-if="index!==3"
              class="package-price flex-row"
            >
              <span class="package-price-symbol">￥</span>
              {{ item.price }}
              <span class="package-price-unit">/年</span>
            </div>
            <span
              v-if="index!==3"
              class="package-price-month"
            >￥{{ item.unitPrice }}/月</span>

            <div
              v-if="index!==3"
              class="flex-center buy-button"
            >
              立即购买
            </div>
            <span
              v-if="index!==3"
              class="apply-free"
            >申请免费7天试用</span>
            <div
              v-if="index===3"
              class="package-vip flex-row"
            >
              <i class="iconfont icon-shiting1"></i>
              VIP定制咨询
            </div>
            <div
              v-if="index===3"
              class="flex-center call-button"
            >
              联系我们
            </div>
          </div>
          <div class="config-container">
            <div class="desc-title">
              配置
            </div>
            <div
              v-if="index!==3"
              style="height: 260px;"
            >
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  用户数
                </span>
                <div class="item-num">
                  {{ item.profileCount }}个
                </div>
              </div>
              <div class="flex-row-center mb8">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  绑定账号数量
                </span>
                <div class="item-num">
                  {{ item.profileCount*6 }}个
                </div>
              </div>
              <div style="margin-left: 14px;">
                Tiktok、Instagram、YouTube、Facebook、X、LinkedIn 6平台各{{ item.profileCount }}个
              </div>
              <div class="flex-row-center mt10 mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  存储空间
                </span>
                <div class="item-num">
                  {{ item.fileSize/1024 }}G
                </div>
              </div>
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  视频制作时长
                </span>
                <div class="item-num">
                  {{ item.productDuration }}分钟
                </div>
              </div>
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  发布
                </span>
                <div class="item-num">
                  {{ item.publishCount }}条
                </div>
              </div>
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  回复评论
                </span>
                <div class="item-num">
                  {{ item.commentCount }}条
                </div>
              </div>
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  私信
                </span>
                <div class="item-num">
                  {{ item.messageCount }}条
                </div>
              </div>
            </div>
            <div
              v-else
              style="height: 260px;"
            >
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  包含专业版所有功能
                </span>
              </div>
              <div class="flex-row-center mb10">
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  深度功能定制及专家技术服务
                </span>
              </div>
            </div>
            <div class="desc-title mt20">
              功能
            </div>
            <div class="package-list">
              <div
                v-for="item in item.fun"
                :key="item"
                class="flex-row-center item-fun"
              >
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  {{ item }}
                </span>
                <i class="iconfont icon-dagou f-shrink"></i>
              </div>
            </div>
            <div class="desc-title mt20">
              服务
            </div>
            <div class="package-list">
              <div
                v-for="item in item.service"
                :key="item"
                class="flex-row-center item-fun"
              >
                <div class="point-blue f-shrink"></div>
                <span class="f-grow">
                  {{ item }}
                </span>
                <i class="iconfont icon-dagou f-shrink"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 增购套餐 -->
    <div class="extra-packages flex-column-center">
      <div class="extra-title">增购套餐包</div>
      <img
        class="extra-bg"
        src="../assets/images/package/package-extra-bg.png"
        alt=""
      />
      <div class="extra-card flex-row">
        <div class="w-50 extra-card-left">
          <div class="card-title">增购套餐包</div>
          <div class="card-des">适用于需要额增加榜定账号数量、储存空间或AI创作时长的团队，且团队内所有成员共享。</div>
          <div class="card-price flex-row">
            <span class="card-price-symbol">￥</span>
            2000
            <span class="card-price-unit">元</span>
          </div>
          <div class="flex-center card-buy-button">
            立即购买
          </div>
          <div class="card-tip">
            *高性能包需与基础版/标准版/专业版套餐配合使用，不支持单独购买。
          </div>
        </div>
        <div class="w-50 card-right flex-column-center f-center">
          <div
            v-for="(item, index) in extraPackagesDes"
            :key="index"
            class="flex-row-center f-between card-right-item"
          >
            <div class="card-right-title">{{ item.title }}</div>
            <div class="card-right-num">{{ item.num }}</div>
            <!-- <div class="card-line"></div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="questions-title">常见问题</div>
    <!-- 常见问题 -->
    <div class="questions">
      <div
        class="question-item"
        v-for="(question, index) in questionsList"
        :key="index"
        @click="question.show = !question.show"
      >
        <div class="flex-row-center question-item-title">
          <i :class="question.show ?'el-icon-caret-bottom':'el-icon-caret-right'"></i>
          {{ question.question }}
        </div>
        <div
          class="question-item-answer"
          v-if="question.show"
        >
          {{ question.answer }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listPackage } from "../api/mall/package";

export default {
  data() {
    const fun1 = [
      "工作台",
      "商城——买家",
      "账号管理",
      "素材管理",
      "创作-视频混剪",
      "创作-文生视频",
      "创作-AI视频",
      "AI工坊-视频AI翻译",
      "AI工坊-视频搬运",
      "作品管理",
      "发布管理",
      "支持多语种",
      "AI销售",
      "发布流",
      "消息中心",
      "爆款拆解",
      "用户中心",
    ];

    const fun2 = [...fun1, "商城—卖家", "素材切片", "运营计划"];

    const fun3 = [
      ...fun2,
      "AI工坊-声音克隆",
      "AI工坊-AI换脸",
      "AI工坊-去字幕",
      "AI工坊-语音翻译",
      "数据中心",
    ];
    const service1 = ["在线客服答疑"];
    const service2 = ["在线客服答疑", "一对一全方位专家运营指导"];
    return {
      plans: [
        {
          name: "基础版",
          price: "19800",
          features: ["AI字幕", "视频剪辑", "自动配音", "导出1080P"],
        },
        {
          name: "标准版",
          price: "39800",
          features: ["AI字幕", "视频剪辑", "自动配音", "导出4K", "云存储"],
        },
        {
          name: "专业版",
          price: "99800",
          features: [
            "AI字幕",
            "视频剪辑",
            "自动配音",
            "导出4K",
            "云存储",
            "团队协作",
          ],
        },
        {
          name: "旗舰版",
          price: "定制",
          features: ["AI字幕", "视频剪辑", "自动配音", "无限存储", "VIP客服"],
        },
      ],
      fun1,
      fun2,
      fun3,
      service1,
      service2,
      packageList: [
        {
          name: "基础版",
          des: "适用于个人及小微团队",
          price: "19800",
          unitPrice: "2000",
          profileCount: "2",
          fileSize: "20480",
          publishCount: "500",
          commentCount: "500",
          messageCount: "500",
          productDuration: "500",
          fun: fun1,
          service: service1,
        },
        {
          name: "标准版",
          des: "适合中小型企业及团队",
          price: "39800",
          unitPrice: "4000",
          profileCount: "4",
          fileSize: "102400",
          publishCount: "1000",
          commentCount: "1000",
          messageCount: "1000",
          productDuration: "1000",
          fun: fun2,
          service: service2,
        },
        {
          name: "专业版",
          des: "适合中大型企业<br />提供企业级安全能力和专属服务",
          price: "99800",
          unitPrice: "9800",
          profileCount: "10",
          fileSize: "204800",
          publishCount: "3000",
          commentCount: "3000",
          messageCount: "3000",
          productDuration: "3000",
          fun: fun3,
          service: service2,
        },
        {
          name: "旗舰版",
          des: "满足企业的定制化需求",
          fun: fun3,
          service: service2,
        },
      ],
      extraPackagesDes: [
        { title: "绑定账号数量", num: "2000元/6个(一组)" },
        { title: "存储空间", num: "300/50G" },
        { title: "发布、回复评论、私信", num: "1000条" },
        {
          title: "AI视频创作、计划视频、视频AI翻译、视频搬运",
          num: "600/60分",
        },
      ],
      questionsList: [
        {
          question: "如何购买企业版？",
          answer: "您可以通过官网进行购买，或联系我们的销售人员获取更多信息。",
          show: false,
        },
        {
          question: "Gitee 企业版的费用是多少？",
          answer: "请参考套餐价格页面，或联系客服获取报价。",
          show: false,
        },
      ],
    };
  },
  created() {
    this.getPackage();
  },
  methods: {
    getPackage() {
      listPackage().then((res) => {
        this.packageList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-page {
  background-image: url("../assets/images/package/package-bg.png");
  background-size: cover;
  padding: 160px 0 50px;
}
.package-title {
  width: 761px;
  height: 106px;
  margin-bottom: 90px;
}
.width1280 {
  width: 1280px;
}
.w-25 {
  width: 25%;
}
.border-right {
  border-right: 1px solid #e5e5e5;
}

.pricing-container {
  background: #f9faff;
  border-radius: 12px;
  overflow: hidden;
  .line-head {
    width: 100%;
    height: 8px;
    background: linear-gradient(
      87deg,
      #00ffda 0%,
      #0090ff 25%,
      #a200ff 50%,
      #4500ff 75%,
      #ff00c5 100%
    );
  }
  .base-head {
    height: 318px;
    background: #ffffff;
    border-radius: 0px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .package-name {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 26px;
      color: #111111;
      line-height: 36px;
      letter-spacing: 1px;
      text-align: center;
      font-style: normal;
      margin-top: 24px;
    }
    .package-desc {
      height: 40px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(102, 111, 128, 0.6);
      line-height: 20px;
      text-align: center;
      font-style: normal;
      margin-top: 8px;
    }
    .package-price {
      font-family: AvenirNext, AvenirNext;
      font-weight: 600;
      font-size: 40px;
      color: #202d40;
      line-height: 40px;
      text-align: left;
      font-style: normal;
      margin-top: 30px;
      .package-price-symbol {
        align-self: flex-start;
        font-size: 20px;
        line-height: 20px;
      }
      .package-price-unit {
        align-self: flex-end;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(102, 111, 128, 0.6);
        line-height: 20px;
        text-align: center;
        font-style: normal;
      }
    }
    .package-price-month {
      font-family: AvenirNext, AvenirNext;
      font-weight: 400;
      font-size: 12px;
      color: #202d40;
      line-height: 20px;
      text-align: center;
      font-style: normal;
      margin-top: 12px;
    }
    .package-vip {
      height: 72px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 24px;
      color: #202d40;
      line-height: 33px;
      text-align: center;
      font-style: normal;
      margin-top: 30px;
      i {
        font-size: 24px;
        color: #0156ff;
        margin-right: 16px;
      }
    }
  }
}
.config-container {
  padding: 24px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: rgba(46, 64, 94, 0.6);
  line-height: 20px;
  text-align: left;
  font-style: normal;
  .point-blue {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #0156ff;
    margin-right: 10px;
    display: inline-block;
  }
  .item-num {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 14px;
    color: #2e405e;
    line-height: 20px;
    text-align: right;
    font-style: normal;
  }
}
.buy-button {
  width: 200px;
  height: 40px;
  background: #f0f6ff;
  border-radius: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
  color: #0156ff;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}
.buy-button:hover {
  background-image: linear-gradient(90deg, #0089ff 0%, #6e16d1 100%);
  color: #ffffff;
}
.call-button {
  width: 200px;
  height: 40px;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #0156ff;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #0156ff;
  line-height: 40px;
  font-style: normal;
  margin-top: 20px;
  cursor: pointer;
}
.buy-button:hover {
  box-shadow: 0 0 5px #ccc !important;
}
.apply-free {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #8c92a4;
  line-height: 17px;
  text-align: left;
  font-style: normal;
  margin-top: 20px;
}
.desc-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 14px;
  color: #2e405e;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  margin-bottom: 8px;
}
.package-list {
  .item-fun {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: rgba(46, 64, 94, 0.6);
    line-height: 20px;
    text-align: left;
    font-style: normal;
    margin-bottom: 10px;
  }

  i {
    color: #2ed062;
    font-size: 16px;
    margin-left: 5;
  }
}

.pricing-card {
  text-align: center;
  padding: 20px;
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: #409eff;
}

.buy-btn {
  margin-top: 15px;
}

.extra-packages {
  width: 100%;
  background: #151e40;
  color: #fff;
  padding: 40px 0;
  margin-top: 120px;
  position: relative;
  .extra-bg {
    position: absolute;
    width: 504px;
    height: 495px;
    top: 0;
    left: 200px;
    z-index: 1;
  }

  .extra-title {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 36px;
    color: #ffffff;
    line-height: 50px;
    text-align: left;
    font-style: normal;
  }
  .extra-card {
    width: 1280px;
    margin-top: 70px;
    // background: linear-gradient(to right, #6a11cb, #2575fc);
    background-image: url("../assets/images/package/package-extra.png");
    background-size: cover;
    color: #fff;
    border-radius: 12px;
    z-index: 2;
    .extra-card-left {
      padding: 48px 48px 36px;
      .card-title {
        font-family: PingFang-SC, PingFang-SC;
        font-weight: bold;
        font-size: 36px;
        color: rgba(46, 64, 94, 0.6);
        line-height: 50px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        background: linear-gradient(90deg, #e1ffff 0%, #ffffff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .card-des {
        width: 450px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 20px;
        text-align: left;
        font-style: normal;
        margin-top: 16px;
      }
      .card-price {
        font-family: AvenirNext, AvenirNext;
        font-weight: 600;
        font-size: 40px;
        color: #ffffff;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        margin-top: 90px;

        .card-price-symbol {
          align-self: flex-start;
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
        }
        .card-price-unit {
          align-self: flex-end;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.6);
          line-height: 20px;
          font-style: normal;
        }
      }
      .card-buy-button {
        width: 136px;
        height: 42px;
        background: linear-gradient(
          180deg,
          #ffffff 0%,
          rgba(255, 255, 255, 0.9) 100%
        );
        border-radius: 8px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #2d61fc;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        margin-top: 32px;
        cursor: pointer;
      }
      .card-buy-button:hover {
        box-shadow: 0 0 5px #ccc !important;
      }
      .card-tip {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        margin-top: 16px;
      }
    }
    .card-right {
      // background: linear-gradient(
      //   90deg,
      //   #323082 0%,
      //   rgba(43, 40, 163, 0.19) 100%
      // );
      // backdrop-filter: blur(50px);
      border-radius: 0px 12px 12px 0px;
      .card-right-item {
        width: 640px;
        margin-top: 58px;
        .card-right-title {
          width: 460px;
          font-family: PingFang-SC, PingFang-SC;
          font-weight: bold;
          font-size: 20px;
          color: #ffffff;
          line-height: 28px;
          text-align: left;
          font-style: normal;
        }
        .card-right-num {
          width: 200px;
          font-family: PingFang-SC, PingFang-SC;
          font-weight: bold;
          font-size: 22px;
          color: #ffffff;
          line-height: 32px;
          text-align: left;
          font-style: normal;
        }
      }

      .card-right-item:first-child {
        margin-top: 0;
      }
      .card-right-item:nth-child(1) {
        margin-left: -150px;
      }

      .card-right-item:nth-child(2) {
        margin-left: -70px;
      }

      .card-right-item:nth-child(3) {
        margin-left: 10px;
      }

      .card-right-item:nth-child(4) {
        margin-left: 90px;
      }

      .card-line {
        width: 580px;
        height: 6px;
        background: linear-gradient(
          270deg,
          rgba(148, 94, 243, 0) 8%,
          #5b30e4 100%
        );
        display: inline-block;
        margin-top: -6px;
      }
    }
  }
}
.questions-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: bold;
  font-size: 36px;
  color: #333333;
  line-height: 50px;
  text-align: left;
  font-style: normal;
  margin-top: 40px;
}
.questions {
  margin: 70px 0 150px;
  width: 1280px;
  .question-item {
    background: #f8fafe;
    border-radius: 12px;
    padding: 16px 40px;
    margin-bottom: 16px;
    .question-item-title {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: 500;
      font-size: 20px;
      color: #000000;
      line-height: 30px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      i {
        font-size: 24px;
        margin-right: 8px;
      }
    }
    .question-item-answer {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: 500;
      font-size: 18px;
      color: #555555;
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-top: 16px;
      margin-left: 30px;
    }
  }
}
</style>
