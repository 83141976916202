var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sale-container flex-row" },
    [
      _c("div", { staticClass: "sale-left f-shrink" }, [
        _c(
          "div",
          { staticClass: "topPadding" },
          [
            _c(
              "div",
              { staticClass: "topTitle flex-row-center f-between" },
              [
                _c("div", [_vm._v(_vm._s(_vm.$t("sale.list")))]),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "mini", icon: "el-icon-plus" },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v(_vm._s(_vm.$t("sale.add")))]
                ),
              ],
              1
            ),
            _c(
              "el-input",
              {
                staticClass: "inputStyle",
                attrs: {
                  placeholder: _vm.$t("account.tipAccount"),
                  clearable: "",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.searchValue,
                  callback: function ($$v) {
                    _vm.searchValue = $$v
                  },
                  expression: "searchValue",
                },
              },
              [
                _c("i", {
                  staticClass: "el-input__icon iconfont icon-sousuotubiao",
                  attrs: { slot: "prefix" },
                  on: { click: _vm.handleQuery },
                  slot: "prefix",
                }),
              ]
            ),
            _vm.sales.length == 0
              ? _c("el-empty", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { "image-size": 200 },
                })
              : _vm._e(),
            _vm._l(_vm.sales, function (account, index) {
              return _c(
                "div",
                {
                  key: account.id,
                  staticClass: "saleItem flex-row-center",
                  on: {
                    click: function ($event) {
                      return _vm.clickSale(account)
                    },
                    mouseover: function ($event) {
                      return _vm.mouseoverItem(index)
                    },
                    mouseleave: function ($event) {
                      return _vm.mouseleaveItem(index)
                    },
                  },
                },
                [
                  _vm._m(0, true),
                  _c(
                    "div",
                    { staticClass: "sale-name single-line-ellipsis f-grow" },
                    [_vm._v(_vm._s(account.saleName))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: account.num,
                          expression: "account.num",
                        },
                      ],
                      staticClass: "sale-num flex-center f-shrink",
                    },
                    [_vm._v(_vm._s(account.num))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: account.isHovered,
                          expression: "account.isHovered",
                        },
                      ],
                      staticClass: "overlay",
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-a-xialajiantoubeifen3",
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(account)
                          },
                        },
                      }),
                      _c("i", {
                        staticClass: "iconfont icon-edit",
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(account)
                          },
                        },
                      }),
                      _c("i", {
                        staticClass: "iconfont icon-delete",
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(account)
                          },
                        },
                      }),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticClass: "sale-right f-grow" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentSale.id,
                  expression: "currentSale.id",
                },
              ],
              staticClass: "right-head flex-row-center",
            },
            [
              _c(
                "el-radio-group",
                {
                  staticClass: "f-grow",
                  on: { input: _vm.clickSocialType },
                  model: {
                    value: _vm.socialType,
                    callback: function ($$v) {
                      _vm.socialType = $$v
                    },
                    expression: "socialType",
                  },
                },
                _vm._l(_vm.socialPlatformFilters, function (dict) {
                  return _c(
                    "el-radio",
                    {
                      key: dict.value,
                      attrs: { border: "", label: dict.value },
                    },
                    [_vm._v(" " + _vm._s(dict.label) + " ")]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "right-Tab f-shrink" },
                _vm._l(_vm.replyTypeFilters, function (item) {
                  return _c(
                    "div",
                    {
                      key: item.label,
                      class: ["tabItem", item.isActive && "activeTab"],
                      on: {
                        click: function ($event) {
                          return _vm.clickSearchTable(item)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.label) + " ")]
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.currentSale.id,
                  expression: "!currentSale.id",
                },
              ],
              staticClass: "notMsg",
            },
            [
              _c("div", { staticClass: "notMsgContent" }, [
                _vm.logo
                  ? _c("img", {
                      staticClass: "notMsgLogo",
                      attrs: { src: _vm.logo },
                    })
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "el-collapse",
            {
              attrs: { accordion: "" },
              model: {
                value: _vm.activeNames,
                callback: function ($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames",
              },
            },
            [
              _vm._l(_vm.socialListFilters, function (item, parentIndex) {
                return _c(
                  "div",
                  { key: parentIndex, staticClass: "collapse-item" },
                  [
                    _c(
                      "el-collapse-item",
                      {
                        attrs: { name: item.id },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "accountTitle" }, [
                                    _c(
                                      "div",
                                      { staticClass: "leftAvatar" },
                                      [
                                        _c("el-avatar", {
                                          attrs: {
                                            size: 44,
                                            src: item.userImage,
                                          },
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "criceIcon" },
                                          [
                                            _c("i", {
                                              class: [
                                                "iconfont",
                                                "icon-" +
                                                  item.platform +
                                                  "-normal",
                                              ],
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("div", { staticClass: "ml10" }, [
                                      _vm._v(_vm._s(item.username) + " "),
                                    ]),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm.showType === _vm.$t("sale.commentReply")
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.msgLoading,
                                    expression: "msgLoading",
                                  },
                                ],
                              },
                              [
                                _vm.replyListFilters.length == 0
                                  ? _c("el-empty", {
                                      attrs: { "image-size": 200 },
                                    })
                                  : _vm._e(),
                                _vm._l(
                                  _vm.replyListFilters,
                                  function (account) {
                                    return _c("comment-item", {
                                      key: account.id,
                                      staticStyle: { width: "100%" },
                                      attrs: { comment: account },
                                    })
                                  }
                                ),
                              ],
                              2
                            )
                          : _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.msgLoading,
                                    expression: "msgLoading",
                                  },
                                ],
                              },
                              [
                                _vm.getChildrenById(item.id).length == 0
                                  ? _c("el-empty", {
                                      attrs: { "image-size": 200 },
                                    })
                                  : _vm._e(),
                                _vm._l(
                                  _vm.getChildrenById(item.id),
                                  function (account) {
                                    return _c(
                                      "div",
                                      {
                                        key: account.id,
                                        on: {
                                          click: function ($event) {
                                            return _vm.queryMsgDetail(
                                              account,
                                              item.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "accountBox",
                                              account.isActive
                                                ? "activeBox"
                                                : "",
                                            ],
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "leftAvatar" },
                                              [
                                                _c("el-avatar", {
                                                  attrs: {
                                                    size: 44,
                                                    src: account.profileImage,
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  { staticClass: "criceIcon" },
                                                  [
                                                    _c("i", {
                                                      class: [
                                                        "iconfont",
                                                        "icon-" +
                                                          item.platform +
                                                          "-normal",
                                                      ],
                                                    }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "rightUserDetail",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "detailUserTop",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "userTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            account.nickname
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "userTime",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            account.updateTime
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "detailMsg" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(account.message)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        account.isActive
                                          ? _c("message-content", {
                                              attrs: {
                                                chatMsgList: _vm.msgList,
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.msgList.length == 0 &&
                                        account.isActive
                                          ? _c("el-empty", {
                                              attrs: { "image-size": 200 },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm.socialListFilters.length == 0
                ? _c("el-empty", { attrs: { "image-size": 200 } })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.$t("sale.info"),
            size: "50%",
            visible: _vm.saleDrawer,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.saleDrawer = $event
            },
          },
        },
        [
          _c("Detail", {
            ref: "saleDetail",
            staticStyle: { padding: "20px" },
            on: {
              cancel: function ($event) {
                _vm.saleDrawer = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "circle-img flex-center f-shrink" }, [
      _c("i", { staticClass: "iconfont icon-aixiaoshou" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }