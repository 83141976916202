var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pricing-page flex-column-center" }, [
    _c("img", {
      staticClass: "package-title",
      attrs: {
        src: require("../assets/images/package/package-title.png"),
        alt: "",
      },
    }),
    _c("div", { staticClass: "pricing-container width1280" }, [
      _c("div", { staticClass: "line-head" }),
      _c(
        "div",
        { staticClass: "flex-row" },
        _vm._l(_vm.packageList, function (item, index) {
          return _c("div", { key: index, staticClass: "w-25 border-right" }, [
            _c("div", { staticClass: "base-head" }, [
              _c("span", { staticClass: "package-name" }, [
                _vm._v(_vm._s(item.name)),
              ]),
              _c("span", { staticClass: "package-desc" }, [
                _vm._v(_vm._s(item.desc)),
              ]),
              index !== 3
                ? _c("div", { staticClass: "package-price flex-row" }, [
                    _c("span", { staticClass: "package-price-symbol" }, [
                      _vm._v("￥"),
                    ]),
                    _vm._v(" " + _vm._s(item.price) + " "),
                    _c("span", { staticClass: "package-price-unit" }, [
                      _vm._v("/年"),
                    ]),
                  ])
                : _vm._e(),
              index !== 3
                ? _c("span", { staticClass: "package-price-month" }, [
                    _vm._v("￥" + _vm._s(item.unitPrice) + "/月"),
                  ])
                : _vm._e(),
              index !== 3
                ? _c("div", { staticClass: "flex-center buy-button" }, [
                    _vm._v(" 立即购买 "),
                  ])
                : _vm._e(),
              index !== 3
                ? _c("span", { staticClass: "apply-free" }, [
                    _vm._v("申请免费7天试用"),
                  ])
                : _vm._e(),
              index === 3
                ? _c("div", { staticClass: "package-vip flex-row" }, [
                    _c("i", { staticClass: "iconfont icon-shiting1" }),
                    _vm._v(" VIP定制咨询 "),
                  ])
                : _vm._e(),
              index === 3
                ? _c("div", { staticClass: "flex-center call-button" }, [
                    _vm._v(" 联系我们 "),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "config-container" }, [
              _c("div", { staticClass: "desc-title" }, [_vm._v(" 配置 ")]),
              index !== 3
                ? _c("div", { staticStyle: { height: "260px" } }, [
                    _c("div", { staticClass: "flex-row-center mb10" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" 用户数 "),
                      ]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.profileCount) + "个 "),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-row-center mb8" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" 绑定账号数量 "),
                      ]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.profileCount * 6) + "个 "),
                      ]),
                    ]),
                    _c("div", { staticStyle: { "margin-left": "14px" } }, [
                      _vm._v(
                        " Tiktok、Instagram、YouTube、Facebook、X、LinkedIn 6平台各" +
                          _vm._s(item.profileCount) +
                          "个 "
                      ),
                    ]),
                    _c("div", { staticClass: "flex-row-center mt10 mb10" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" 存储空间 "),
                      ]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.fileSize / 1024) + "G "),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-row-center mb10" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" 视频制作时长 "),
                      ]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.productDuration) + "分钟 "),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-row-center mb10" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [_vm._v(" 发布 ")]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.publishCount) + "条 "),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-row-center mb10" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" 回复评论 "),
                      ]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.commentCount) + "条 "),
                      ]),
                    ]),
                    _c("div", { staticClass: "flex-row-center mb10" }, [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [_vm._v(" 私信 ")]),
                      _c("div", { staticClass: "item-num" }, [
                        _vm._v(" " + _vm._s(item.messageCount) + "条 "),
                      ]),
                    ]),
                  ])
                : _c("div", { staticStyle: { height: "260px" } }, [
                    _vm._m(0, true),
                    _vm._m(1, true),
                  ]),
              _c("div", { staticClass: "desc-title mt20" }, [_vm._v(" 功能 ")]),
              _c(
                "div",
                { staticClass: "package-list" },
                _vm._l(item.fun, function (item) {
                  return _c(
                    "div",
                    { key: item, staticClass: "flex-row-center item-fun" },
                    [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ]),
                      _c("i", { staticClass: "iconfont icon-dagou f-shrink" }),
                    ]
                  )
                }),
                0
              ),
              _c("div", { staticClass: "desc-title mt20" }, [_vm._v(" 服务 ")]),
              _c(
                "div",
                { staticClass: "package-list" },
                _vm._l(item.service, function (item) {
                  return _c(
                    "div",
                    { key: item, staticClass: "flex-row-center item-fun" },
                    [
                      _c("div", { staticClass: "point-blue f-shrink" }),
                      _c("span", { staticClass: "f-grow" }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ]),
                      _c("i", { staticClass: "iconfont icon-dagou f-shrink" }),
                    ]
                  )
                }),
                0
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "extra-packages flex-column-center" }, [
      _c("div", { staticClass: "extra-title" }, [_vm._v("增购套餐包")]),
      _c("img", {
        staticClass: "extra-bg",
        attrs: {
          src: require("../assets/images/package/package-extra-bg.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "extra-card flex-row" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "w-50 card-right flex-column-center f-center" },
          _vm._l(_vm.extraPackagesDes, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "flex-row-center f-between card-right-item",
              },
              [
                _c("div", { staticClass: "card-right-title" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
                _c("div", { staticClass: "card-right-num" }, [
                  _vm._v(_vm._s(item.num)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("div", { staticClass: "questions-title" }, [_vm._v("常见问题")]),
    _c(
      "div",
      { staticClass: "questions" },
      _vm._l(_vm.questionsList, function (question, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "question-item",
            on: {
              click: function ($event) {
                question.show = !question.show
              },
            },
          },
          [
            _c("div", { staticClass: "flex-row-center question-item-title" }, [
              _c("i", {
                class: question.show
                  ? "el-icon-caret-bottom"
                  : "el-icon-caret-right",
              }),
              _vm._v(" " + _vm._s(question.question) + " "),
            ]),
            question.show
              ? _c("div", { staticClass: "question-item-answer" }, [
                  _vm._v(" " + _vm._s(question.answer) + " "),
                ])
              : _vm._e(),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row-center mb10" }, [
      _c("div", { staticClass: "point-blue f-shrink" }),
      _c("span", { staticClass: "f-grow" }, [_vm._v(" 包含专业版所有功能 ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-row-center mb10" }, [
      _c("div", { staticClass: "point-blue f-shrink" }),
      _c("span", { staticClass: "f-grow" }, [
        _vm._v(" 深度功能定制及专家技术服务 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-50 extra-card-left" }, [
      _c("div", { staticClass: "card-title" }, [_vm._v("增购套餐包")]),
      _c("div", { staticClass: "card-des" }, [
        _vm._v(
          "适用于需要额增加榜定账号数量、储存空间或AI创作时长的团队，且团队内所有成员共享。"
        ),
      ]),
      _c("div", { staticClass: "card-price flex-row" }, [
        _c("span", { staticClass: "card-price-symbol" }, [_vm._v("￥")]),
        _vm._v(" 2000 "),
        _c("span", { staticClass: "card-price-unit" }, [_vm._v("元")]),
      ]),
      _c("div", { staticClass: "flex-center card-buy-button" }, [
        _vm._v(" 立即购买 "),
      ]),
      _c("div", { staticClass: "card-tip" }, [
        _vm._v(
          " *高性能包需与基础版/标准版/专业版套餐配合使用，不支持单独购买。 "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }