<template>
  <div class="sale-container flex-row">
    <div class="sale-left f-shrink">
      <div class="topPadding">
        <div class="topTitle flex-row-center f-between">
          <div>{{ $t('sale.list') }}</div>
          <el-button
            type="text"
            size="mini"
            icon="el-icon-plus"
            @click="handleAdd"
          >{{ $t('sale.add') }}</el-button>
        </div>
        <el-input
          class="inputStyle"
          v-model="searchValue"
          :placeholder="$t('account.tipAccount')"
          clearable
          @keyup.enter.native="handleQuery"
        >
          <i
            slot="prefix"
            class="el-input__icon iconfont icon-sousuotubiao"
            @click="handleQuery"
          ></i>
        </el-input>
        <el-empty
          v-loading="loading"
          v-if="sales.length==0"
          :image-size="200"
        ></el-empty>
        <div
          v-for="(account, index) in sales"
          :key="account.id"
          class="saleItem flex-row-center"
          @click="clickSale(account)"
          @mouseover="mouseoverItem(index)"
          @mouseleave="mouseleaveItem(index)"
        >
          <div class="circle-img flex-center f-shrink">
            <i class="iconfont icon-aixiaoshou"></i>
          </div>
          <div class="sale-name single-line-ellipsis f-grow">{{ account.saleName }}</div>
          <div
            class="sale-num flex-center f-shrink"
            v-show="account.num"
          >{{ account.num }}</div>
          <div
            v-show="account.isHovered"
            class="overlay"
          >
            <i
              class="iconfont icon-a-xialajiantoubeifen3"
              @click="handleDetail(account)"
            ></i>
            <i
              class="iconfont icon-edit"
              @click="handleUpdate(account)"
            ></i>
            <i
              class="iconfont icon-delete"
              @click="handleDelete(account)"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div class="sale-right f-grow">
      <div
        v-show="currentSale.id"
        class="right-head flex-row-center"
      >
        <el-radio-group
          v-model="socialType"
          class="f-grow"
          @input="clickSocialType"
        >
          <el-radio
            v-for="dict in socialPlatformFilters"
            border
            :key="dict.value"
            :label="dict.value"
          >
            {{ dict.label }}
          </el-radio>
        </el-radio-group>
        <div class="right-Tab f-shrink">
          <div
            v-for="item in replyTypeFilters"
            :key="item.label"
            :class="['tabItem', item.isActive && 'activeTab']"
            @click="clickSearchTable(item)"
          >
            {{ item.label }}
          </div>
        </div>
      </div>
      <div
        v-show="!currentSale.id"
        class="notMsg"
      >
        <div class="notMsgContent">
          <img
            v-if="logo"
            :src="logo"
            class="notMsgLogo"
          />
        </div>
      </div>
      <el-collapse
        v-model="activeNames"
        accordion
      >
        <div
          v-for="(item, parentIndex) in socialListFilters"
          :key="parentIndex"
          class="collapse-item"
        >
          <el-collapse-item :name="item.id">
            <template #title>
              <div class="accountTitle">
                <div class="leftAvatar">
                  <el-avatar
                    :size="44"
                    :src="item.userImage"
                  ></el-avatar>
                  <div class="criceIcon">
                    <i :class="['iconfont', 'icon-'+item.platform+'-normal']"></i>
                  </div>
                </div>
                <div class="ml10">{{ item.username }} </div>
              </div>
            </template>
            <div
              v-if="showType === $t('sale.commentReply')"
              v-loading="msgLoading"
            >
              <el-empty
                v-if="replyListFilters.length==0"
                :image-size="200"
              ></el-empty>
              <comment-item
                style="width: 100%;"
                v-for="account in replyListFilters"
                :key="account.id"
                :comment="account"
              />
            </div>
            <div
              v-else
              v-loading="msgLoading"
            >
              <el-empty
                v-if="getChildrenById(item.id).length==0"
                :image-size="200"
              ></el-empty>
              <div
                v-for="account in getChildrenById(item.id)"
                :key="account.id"
                @click="queryMsgDetail(account,item.id)"
              >
                <div :class="['accountBox', account.isActive ? 'activeBox' : '']">
                  <div class="leftAvatar">
                    <el-avatar
                      :size="44"
                      :src="account.profileImage"
                    ></el-avatar>
                    <div class="criceIcon">
                      <i :class="['iconfont', 'icon-'+item.platform+'-normal']"></i>
                    </div>
                  </div>
                  <div class="rightUserDetail">
                    <div class="detailUserTop">
                      <div class="userTitle">{{ account.nickname }}</div>
                      <div class="userTime">{{ account.updateTime }}</div>
                    </div>
                    <div class="detailMsg">{{ account.message }}</div>
                  </div>
                </div>
                <message-content
                  v-if="account.isActive"
                  :chatMsgList="msgList"
                />
                <el-empty
                  v-if="msgList.length==0&&account.isActive"
                  :image-size="200"
                ></el-empty>
              </div>
            </div>
          </el-collapse-item>
        </div>
        <el-empty
          v-if="socialListFilters.length==0"
          :image-size="200"
        ></el-empty>
      </el-collapse>
    </div>
    <!-- 查看、编辑抽屉 -->
    <el-drawer
      :title="$t('sale.info')"
      size="50%"
      :visible.sync="saleDrawer"
      :with-header="false"
    >
      <Detail
        ref="saleDetail"
        @cancel="saleDrawer = false"
        style="padding: 20px;"
      ></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { msgDetail, msgList } from "../../api/message";
import { delSale, listSale, queryAiReplyList } from "../../api/sale/sale";
import CommentItem from "./components/CommentItem.vue";
import MessageContent from "./components/messageContent.vue";
import logoImg from "@/assets/logo/logo.png";
import Detail from "./detail.vue";
export default {
  name: "aiSale",
  dicts: ["social_platform"],
  components: {
    CommentItem,
    MessageContent,
    Detail,
  },
  data() {
    return {
      logo: logoImg,
      searchValue: "", //搜索的用户名、账号、昵称
      activeNames: "",
      showType: this.$t("sale.commentReply"), //当前回复方式
      sales: [], //ai销售列表
      currentSale: {}, //当前ai销售
      socialType: "facebook", //当前选中的平台类型
      tabList: [
        // {
        //   label: "全部回复",
        //   isActive: true,
        // },
        {
          label: this.$t("sale.commentReply"),
          value: "comment",
          isActive: true,
        },
        {
          label: this.$t("sale.messageReply"),
          value: "message",
          isActive: false,
        },
      ],
      queryParams: {
        // pageNum: 1,
        // pageSize: 10,
      },
      loading: false,
      msgLoading: false,
      replyList: [], //评论回复列表
      saleDrawer: false,
      msgListAll: [], //消息回复列表——根据平台查询
      msgList: [], //消息回复列表
    };
  },
  computed: {
    // 当前显示的平台类型——创建AI销售时选择的
    socialPlatformFilters() {
      if (
        !this.currentSale ||
        !this.currentSale.socialType ||
        typeof this.currentSale.socialType !== "string"
      ) {
        return [];
      }
      // 转换 socialType 为数组
      const socialTypeArray = this.currentSale.socialType.split(",");
      const result = this.dict.type.social_platform.filter((type) =>
        socialTypeArray.includes(type.value)
      );
      this.socialType = result[0].value;
      return result || [];
    },
    // 当前显示的回复类型——创建AI销售时选择的
    replyTypeFilters() {
      if (
        !this.currentSale ||
        !this.currentSale.replyType ||
        typeof this.currentSale.replyType !== "string"
      ) {
        return [];
      }
      // 转换 replyType 为数组
      const replyTypeArray = this.currentSale.replyType.split(",");
      const result = this.tabList.filter((type) =>
        replyTypeArray.includes(type.value)
      );
      this.showType = result[0].label;
      return result || [];
    },
    // 当前显示的社交账号列表——根据平台类型筛选
    socialListFilters() {
      if (
        !this.currentSale ||
        !this.currentSale.socialList ||
        this.currentSale.socialList.length == 0
      ) {
        return [];
      }
      return (
        this.currentSale.socialList.filter(
          (item) => item.platform === this.socialType
        ) || []
      );
    },
    // 当前显示的回复列表——评论
    replyListFilters() {
      return (
        this.replyList.filter(
          (item) => item.publishSocialId === this.activeNames
        ) || []
      );
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getChildrenById(socialId) {
      // 判空：确保 msgListAll 存在并且是数组
      if (!Array.isArray(this.msgListAll)) {
        console.warn("msgListAll is not a valid array");
        return [];
      }
      // 找到对应的 msgListAll 条目
      const match = this.msgListAll.find((item) => item.socialId === socialId);
      // 如果找到，返回 children（确保 children 是数组），否则返回空数组
      return match && Array.isArray(match.children) ? match.children : [];
    },
    //社媒变化时的回调
    clickSocialType() {
      this.queryReplyList();
      this.queryMsgList();
    },
    queryMsgList() {
      msgList({
        sociaIds: this.currentSale.id,
        platform: this.socialType,
      }).then((res) => {
        this.msgListAll = res.data;
        this.msgListAll.forEach((item) => {
          if (item.children && Array.isArray(item.children)) {
            item.children.forEach((child) => {
              child.isActive = false;
            });
          }
        });
      });
    },
    // 查询消息列表
    queryMsgDetail(account, socialId) {
      if (!Array.isArray(this.msgListAll)) {
        console.warn("msgListAll is not a valid array");
        return;
      }
      // 找到对应的 msgListAll 条目
      const match = this.msgListAll.find((item) => item.socialId === socialId);
      if (match && Array.isArray(match.children)) {
        // 检查当前 account 的 isActive 值是否为 true
        const currentIsActive = match.children.some(
          (child) =>
            child.conversationId === account.conversationId && child.isActive
        );
        // 遍历 children，设置 isActive 值
        match.children.forEach((child) => {
          if (currentIsActive) {
            // 如果当前 account 的 isActive 为 true，其他 child 的 isActive 设置为 false
            child.isActive = false;
          } else {
            // 否则，维持原逻辑
            child.isActive = child.conversationId === account.conversationId;
          }
        });
        // 检查是否所有 child.isActive 都为 false
        const allInactive = match.children.every((child) => !child.isActive);
        if (allInactive) {
          console.info(
            "All children are inactive, skipping msgDetail request."
          );
          // 强制触发页面更新
          this.$set(this, "msgListAll", [...this.msgListAll]);
          return; // 如果所有 child 的 isActive 为 false，直接返回
        }
      } else {
        console.warn(`No match found for socialId: ${socialId}`);
      }

      this.msgLoading = true;
      msgDetail({
        conversationId: account.conversationId,
        // aiSaleId: this.currentSale.id,
        platform: this.socialType,
      })
        .then((res) => {
          if (res.data && res.data.length) {
            const newList = res.data.map((item) => {
              const detail = JSON.parse(item.senderDetails);
              let otherMessage = null;
              if (item.attachments) {
                otherMessage = JSON.parse(item.attachments);
              }
              return {
                ...item,
                avatar:
                  item.action === "received"
                    ? detail.profileImage
                    : require("@/assets/images/saleAi.png"),
                formMe: item.action === "sent",
                time: item.created,
                userName: detail.name,
                messageType: otherMessage
                  ? otherMessage[0].type
                    ? otherMessage[0].type
                    : ""
                  : "",
                msgUrl: otherMessage
                  ? otherMessage[0].url
                    ? otherMessage[0].url
                    : ""
                  : "",
              };
            });
            this.msgList = newList;
          }
        })
        .finally(() => {
          this.msgLoading = false;
        });
    },
    // 查询评论列表
    queryReplyList() {
      this.msgLoading = true;
      queryAiReplyList({
        aiSaleId: this.currentSale.id,
        platform: this.socialType,
      })
        .then((res) => {
          this.replyList = res.data || [];
        })
        .finally(() => {
          this.msgLoading = false;
        });
    },
    mouseoverItem(index) {
      this.$set(this.sales[index], "isHovered", true);
    },
    mouseleaveItem(index) {
      this.$set(this.sales[index], "isHovered", false);
    },
    // 切换回复方式
    clickSearchTable(item) {
      this.replyTypeFilters.forEach((tabItem) => {
        if (item.label === tabItem.label) {
          this.showType = item.label;
          tabItem.isActive = true;
          this.handleQuery();
          if (this.showType === this.$t("sale.commentReply")) {
            this.queryReplyList();
          } else if (this.showType === this.$t("sale.messageReply")) {
            this.queryMsgList();
          }
        } else {
          tabItem.isActive = false;
        }
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({ path: "/addSale" });
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.$router.push({ path: `/addSale/${row.id}` });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$modal
        .confirm(this.$t("sale.confirmDelete"))
        .then(() => {
          return delSale(row.id);
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess(this.$t("tip.remove"));
        });
    },
    /** 详情按钮操作 */
    handleDetail(row) {
      this.saleDrawer = true;
      this.$nextTick().then((rs) => {
        this.$refs.saleDetail.init(row.id, false);
      });
    },
    getList() {
      this.loading = true;
      listSale({ searchValue: this.searchValue })
        .then((res) => {
          this.sales = (res.rows || []).map((item) => ({
            ...item,
            isHovered: false,
          }));
        })
        .catch((err) => {
          this.sales = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleQuery() {
      this.getList();
    },
    clickSale(sale) {
      this.currentSale = sale;
      if (
        this.socialPlatformFilters.length > 0 &&
        this.replyTypeFilters.length > 0 &&
        this.showType === this.$t("sale.commentReply")
      ) {
        this.queryReplyList();
      } else if (
        this.socialPlatformFilters.length > 0 &&
        this.replyTypeFilters.length > 0 &&
        this.showType === this.$t("sale.messageReply")
      ) {
        this.queryMsgList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sale-container {
  padding: 20px;
}
.sale-left {
  width: 320px;
  height: calc(100vh - 130px);
  background: #ffffff;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border: 1px solid #ebebeb;
  margin-right: 16px;
  overflow: hidden;
  .topPadding {
    padding: 14px 16px;
    .topTitle {
      font-family: PingFang-SC, PingFang-SC;
      font-weight: bold;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
      line-height: 20px;
      text-align: left;
      font-style: normal;
      margin-bottom: 22px;
    }
    .inputStyle {
      background: #ffffff;
      border-radius: 8px;
      .el-input__inner {
        height: 32px;
        border-radius: 8px !important;
      }
      .el-input__icon {
        line-height: 32px;
      }
    }

    .saleItem {
      position: relative;
      width: 100%;
      height: 64px;
      background: white;
      border-radius: 10px;
      margin-top: 8px;
      padding: 0 10px;

      .circle-img {
        width: 44px;
        height: 44px;
        background: #6a5ae0;
        border-radius: 50%;
        color: white;
        font-size: 26px;
        margin-right: 10px;
      }
      .sale-name {
        font-family: PingFang-SC, PingFang-SC;
        font-weight: bold;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
      .sale-num {
        height: 24px;
        min-width: 24px;
        background: #ff0000;
        border-radius: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        font-style: normal;
        padding: 0 4px;
      }
      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background: white;
        display: flex;
        background: #f2f8fe;
        justify-content: flex-end;
        align-items: center;
        font-size: 14px;

        .iconfont {
          font-size: 14px;
          color: #2e74ff;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
    .saleItem:hover {
      background: #f2f8fe;
    }
  }
}
.sale-right {
  height: calc(100vh - 130px);

  .right-head {
    background: linear-gradient(
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.2) 100%
    );
    // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
    border: 1px solid #ffffff;
    padding: 16px;
  }
  .right-Tab {
    display: flex;
    .tabItem {
      width: 56px;
      height: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.26);
      line-height: 20px;
      text-align: left;
      font-style: normal;
      cursor: pointer;
      margin-left: 20px;
    }
    .activeTab {
      font-weight: bold;
      color: #0156ff;
    }
  }
}
.el-collapse {
  border: none;
  height: 85%;
  overflow: auto;
  // padding: 0 16px;
  padding-bottom: 20px;

  ::v-deep .el-collapse-item__header {
    border-bottom-color: transparent;
    padding-bottom: 0;
  }
  ::v-deep .el-collapse-item__header.is-active {
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 15px;
  }
  ::v-deep .el-collapse-item__wrap {
    border-bottom: 1px solid transparent;
  }
  .accountTitle {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #016fee;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    display: flex;
    align-items: center;
    .criceIconUser {
      width: 24px;
      height: 24px;
      background: #deedfe;
      border-radius: 57px;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-zhanghaoguanli {
        color: #016fee;
      }
    }
  }
  .accountBox {
    cursor: pointer;
    width: 100%;
    // height: 64px;
    height: 74px;
    max-height: 74px;
    background: #ffffff;
    padding: 0 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .rightUserDetail {
      width: 100%;
      margin-left: 10px;
      .detailUserTop {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .userTitle {
          font-family: PingFang-SC, PingFang-SC;
          font-weight: bold;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.8);
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
        .userTime {
          font-family: PingFang-SC, PingFang-SC;
          font-weight: 400;
          font-size: 12px;
          color: #bfccdf;
          line-height: 17px;
          text-align: left;
          font-style: normal;
        }
      }
      .detailMsg {
        margin-top: 8px;
        width: 190px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }
    }
  }
  .activeBox {
    background: #f2f8fe;
    .criceIcon {
      border: 1px solid #0156ff !important;
    }
  }
}
.collapse-item {
  background: #ffffff;
  // box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid #ebebeb;
  border-radius: 14px;
  margin-top: 16px;
  padding: 16px 24px;
}
.leftAvatar {
  width: 45px;
  height: 45px;
  position: relative;
  .criceIcon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid #dcdcdc;
    position: absolute;
    bottom: -3px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont {
      font-size: 12px;
    }
    .icon-youtube-normal {
      font-size: 10px;
    }
  }
}

.notMsg {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  background: #ffffff;
  // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  border: 1px solid #ebebeb;
  padding: 16px;
  .notMsgContent {
    width: 100%;
    height: 100%;
    background: #e7eef5;
    border-radius: 10px;
    opacity: 0.2;
    display: flex;
    justify-content: center;
    align-items: center;
    .notMsgLogo {
      width: 160px;
    }
  }
}
</style>
