<template>
  <div
    class="flex-column h-100"
    v-loading="loading"
  >
    <el-descriptions
      label-class-name="my-label"
      :title="$t('sale.roleSetting')"
      :column="1"
      border
    >
      <el-descriptions-item>
        <template slot="label">{{ $t('sale.name') }}</template>
        <span>{{ detail.saleName }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('sale.replyMethod') }}</template>
        <span>{{ replyTypeFilters }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('sale.model') }}</template>
        <span>{{ modelListFilters }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('time.createTime') }}</template>
        <span>{{ detail.createTime }}</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('sale.description') }}</template>
        <span>{{ detail.description }}</span>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions
      label-class-name="my-label"
      :title="$t('sale.roleAssignment')"
      :column="1"
      border
    >
      <el-descriptions-item>
        <template slot="label">{{ $t('sale.socialMedia') }}</template>
        <span>{{ socialPlatformFilters }}</span>
      </el-descriptions-item>

      <el-descriptions-item
        v-for="(item, index) in groupByPlatform"
        :key="index"
      >
        <template slot="label">{{ item.platform }}{{ $t('sale.account') }}</template>
        <div
          v-for="user in item.list"
          :key="user.id"
          class="user-item"
        >
          <img
            :src="user.userImage"
            alt="User Image"
            class="user-image"
          />
          <p>{{ user.displayName }}</p>
        </div>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">{{ $t('sale.intentReply') }}</template>
        <span>{{ detail.intentionReply }}</span>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
import { listModel } from "../../api/model/model";
import { getSale } from "../../api/sale/sale";

export default {
  name: "Detail",
  dicts: ["social_platform"],
  data() {
    return {
      id: "",
      loading: false,
      detail: {},
      tabList: [
        {
          label: this.$t("sale.commentReply"),
          value: "comment",
          isActive: true,
          statusList: [1, 2, 3],
        },
        {
          label: this.$t("sale.messageReply"),
          value: "message",
          isActive: false,
          statusList: [0],
        },
      ],
      modelList: [],
    };
  },
  computed: {
    // 应用社媒
    socialPlatformFilters() {
      if (
        !this.detail ||
        !this.detail.socialType ||
        typeof this.detail.socialType !== "string"
      ) {
        return "";
      }
      // 转换 socialType 为数组
      const socialTypeArray = this.detail.socialType.split(",");
      const result = socialTypeArray
        .map((type) => {
          const match = this.dict.type.social_platform.find(
            (item) => item.value === type
          );
          return match ? match.label : null;
        })
        .filter((label) => label !== null) // 过滤掉找不到的值
        .join(",");
      return result || "";
    },
    // 回复类型
    replyTypeFilters() {
      if (
        !this.detail ||
        !this.detail.replyType ||
        typeof this.detail.replyType !== "string"
      ) {
        return "";
      }
      // 转换 replyType 为数组
      const replyTypeArray = this.detail.replyType.split(",");
      const result = replyTypeArray
        .map((type) => {
          const match = this.tabList.find((item) => item.value === type);
          return match ? match.label : null;
        })
        .filter((label) => label !== null) // 过滤掉找不到的值
        .join(",");
      return result || "";
    },
    // 当前显示的社交账号列表——根据平台类型筛选
    socialListFilters() {
      if (
        !this.detail ||
        !this.detail.socialList ||
        this.detail.socialList.length == 0
      ) {
        return [];
      }
      return (
        this.detail.socialList.filter(
          (item) => item.platform === this.socialType
        ) || []
      );
    },
    modelListFilters() {
      if (!this.modelList || this.modelList.length == 0) {
        return "";
      }
      const foundItem = this.modelList.find(
        (item) => item.id === this.detail.modelId
      );
      return foundItem ? foundItem.modelName : "";
    },
    groupByPlatform() {
      if (
        !Array.isArray(this.detail.socialList) ||
        this.detail.socialList.length === 0
      ) {
        return [];
      }
      const result = {};
      this.detail.socialList.forEach((item) => {
        if (!result[item.platform]) {
          result[item.platform] = {
            platform: item.platform,
            list: [],
          };
        }
        result[item.platform].list.push(item);
      });

      return Object.values(result);
    },
  },
  created() {
    this.getModelList();
  },
  methods: {
    init(id) {
      this.id = id;
      this.getDetail();
    },
    getDetail() {
      this.loading = true;
      getSale(this.id)
        .then((res) => {
          this.detail = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getModelList() {
      listModel().then((response) => {
        this.modelList = response.rows;
      });
    },
  },
};
</script>
<style scoped>
.my-label {
  width: 100px;
}
.model-title {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0;
}
.success-blue {
  color: #0156ff;
}
.el-icon-circle-check {
  font-size: 16px;
  margin-right: 6px;
}
</style>
