var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "flex-column h-100",
    },
    [
      _c(
        "el-descriptions",
        {
          attrs: {
            "label-class-name": "my-label",
            title: _vm.$t("sale.roleSetting"),
            column: 1,
            border: "",
          },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("sale.name"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.saleName))]),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("sale.replyMethod"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.replyTypeFilters))]),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("sale.model"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.modelListFilters))]),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("time.createTime"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.createTime))]),
            ],
            2
          ),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("sale.description"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.description))]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "el-descriptions",
        {
          attrs: {
            "label-class-name": "my-label",
            title: _vm.$t("sale.roleAssignment"),
            column: 1,
            border: "",
          },
        },
        [
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("sale.socialMedia"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.socialPlatformFilters))]),
            ],
            2
          ),
          _vm._l(_vm.groupByPlatform, function (item, index) {
            return _c(
              "el-descriptions-item",
              { key: index },
              [
                _c("template", { slot: "label" }, [
                  _vm._v(
                    _vm._s(item.platform) + _vm._s(_vm.$t("sale.account"))
                  ),
                ]),
                _vm._l(item.list, function (user) {
                  return _c("div", { key: user.id, staticClass: "user-item" }, [
                    _c("img", {
                      staticClass: "user-image",
                      attrs: { src: user.userImage, alt: "User Image" },
                    }),
                    _c("p", [_vm._v(_vm._s(user.displayName))]),
                  ])
                }),
              ],
              2
            )
          }),
          _c(
            "el-descriptions-item",
            [
              _c("template", { slot: "label" }, [
                _vm._v(_vm._s(_vm.$t("sale.intentReply"))),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.detail.intentionReply))]),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }